.step-connector {
  flex: 1 1 auto;

  &--alternative-label {
    position: absolute;
    top: 12px;
    left: calc(-50% + 20px);
    right: calc(50% + 20px);
  }

  &--active {
    .step-connector__line {
      border-color: $primaryColorMain; // 活躍步驟的線條顏色
    }
  }

  &--completed {
    .step-connector__line {
      border-color: $primaryColorMain; // 已完成步驟的線條顏色
    }
  }

  &--disabled {
    .step-connector__line {
      border-color: $dividerColor;
      opacity: 0.5;
    }
  }

  &__line {
    display: block;
    border-color: $dividerColor;
    border-top-style: solid;
    border-top-width: 1px;

    // 水平方向樣式
    &--horizontal {
      border-top-color: #e0e0e0;
      border-top-width: 2px;
    }

    // 垂直方向樣式
    &--vertical {
      border-left-color: #e0e0e0;
      border-left-width: 2px;
    }
  }
}
