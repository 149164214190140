.stepper {
    &-tabs {
        padding: 1rem 2.4rem 0;
        position: sticky;
        top: 0;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $dividerColor;
            height: 1px;
            width: 100%;
        }
        &-panel {
            padding: 2.4rem 3rem 3rem;
        }
        &-footer {
            text-align: center;
            padding: 2rem 3rem;
            background-color: $bg-light;
            > .btn-group {
                position: relative;
                > .prev {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                > .next {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
        .button-base {
            padding: 2.4rem 3rem 1.6rem;
            &::before {
                --size: 0.7rem;
                content: '';
                position: absolute;
                top: 5px;
                left: 50%;
                transform: translateX(-50%);
                background-color: $errorColorMain;
                height: var(--size);
                width: var(--size);
                border-radius: 50%;
                opacity: 0;
            }
            &.state {
                &::before {
                    opacity: 1;
                }
            }
        }
        .isError {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: $errorColorMain;
                right: 1rem;
                top: 2rem;
            }
        }
    }
}
