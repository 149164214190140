.payment-card {
    color: $fontColor-2;
    background-color: white;
    padding: 1.5rem;
    border: 1px solid $borderColor-2;
    border-radius: $paperBorderRadius;
    position: relative;
    &-title {
        margin: 0;
        padding-top: 0;
    }
    &-img {
        width: 12rem;
        background-color: $page-bg;
        margin-right: 3rem;
        font-weight: $fontWeightMedium;
        @media (max-width: $breakpoint-md) {
            width: 8rem;
            margin-right: 1.2rem;
        }
        > img {
            width: 100%;
            height: auto;
            vertical-align: middle;
        }
    }
    &-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
    &-btn {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
    &-type-select {
        font-size: 1.2rem;
        padding: 1.2rem 0 0 0;
    }
}

.payment-dialog {
    &-title {
        padding: 1rem 2rem;
        margin-bottom: 1.5rem;
        font-weight: bold;
        background-color: $bg-light;
    }
}
