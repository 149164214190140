.step-label {
  display: flex;
  align-items: center;

  &--alternative-label {
    flex-direction: column;
  }

  &--disabled {
    cursor: default;
  }

  &--vertical {
    text-align: left;
    padding: 8px 0;
  }
}

.step-label__label {
  font-size: $body2Size;
  font-weight: $fontWeightMedium;
  line-height: 1.167;
  display: block;
  transition: color 0.2s;

  &--active {
    color: $fontColor-1;
    font-weight: $fontWeightMedium;
  }

  &--completed {
    color: $fontColor-1;
    font-weight: $fontWeightMedium;
  }

  &--alternative-label {
    margin-top: 10px;
  }

  &--error {
    color: $errorColorDarken;
  }
}

.step-label__icon-container {
  display: flex;
  flex-shrink: 0;
  padding-right: 8px;

  &--alternative-label {
    padding-right: 0;
  }
}

.step-label__label-container {
  width: 100%;
  color: $fontColor-4;

  &--alternative-label {
    text-align: center;
  }

}

