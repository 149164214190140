.spinner {

}

.spinner {
  --size: 32px;
  width: var(--size);
  height: var(--size);
  border: 5px solid rgba(0, 0, 0, .2);
  border-top: 5px solid $primaryColorMain;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  &-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, .5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}