.profile-image-uploader {
  position: relative;

}

.crop-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  align-items: center;
  z-index: $modalZIndex;
  .crop-wrap {
    position: relative;
    width: 100%;
    height: 400px;
    margin-bottom: 80px;
  }
  .zoom-range-label {
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    gap: 20px;
    padding: 10px 30px;
  }
  .zoom-range {
    width: 100%;
    margin: 10px 0;
  }
  .re-upload-button {
    cursor: pointer;
  }
  .crop-controller {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    //background: lighten(black, 10%);
    z-index: 1;
  }
  .reactEasyCrop_CropArea {
    background-image: url('../../../scripts/icoach/assets/img/profile-picture-cover-b.png');
    background-size: 100% 100%;
  }
  .btn-group {
    text-align: center;

  }
}

.profile-image-display {
  position: relative;
  display: inline-block;
  cursor: pointer;
  .profile-image {
    > img {
      max-width: 100%;
      height: auto;
      border-radius: 50%;
    }
  }
}



