.settle-cash-box {
    position: relative;
    &-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2.4rem 2.4rem 2rem;
    }
    .col {
        + .col {
            position: relative;;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                @include mxFakeDashed(gray(400), 6px, col);
            }
        }
    }
    .list {
        padding-bottom: 0;
    }
    &-item {
        padding: 0.8rem 2.4rem;
        .price {
            font-weight: $fontWeightBold;
        }
        &.total {
            padding-top: 2rem;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                @include mxFakeDashed();
            }
        }
        &:last-child {
            padding-bottom: 2rem;
        }
    }
    .settle-info {
        &-item {
            display: flex;
            flex-wrap: wrap;
            > span.typography-body1 {
                order: 1;
                font-weight: $fontWeightMedium;
            }
            > p.typography-body2 {
                font-size: $body1Size;
                margin-right: 2rem;
            }
            &.note {
                > p.typography-body2 {
                    margin-bottom: 1.2rem;
                }
                > span.typography-body1 {
                    width: 100%;
                    min-height: 8rem;
                    padding: 1.6rem;
                    border-radius: $shapeBorderRadius;
                    background-color: $bg-light;
                    font-weight: $fontWeightRegular;
                }
            }
        }
    }
}
