$bookmarkWidth: 22;
.contact-record-card {
    &-markbook {
        position: relative;
        align-self: center;
        &:before {
            content: "";
            position: absolute;
            background-color: #a45bc8;
            top: 0;
            bottom: 0;
            left: ($bookmarkWidth * -1) + px;
            right: 0;
            border-top-right-radius: 0.2rem;
            border-bottom-right-radius: 0.2rem;
            box-shadow: map-get($shadows, 2);
        }
    }
    &-date {
        position: relative;
        font-size: 1.6rem;
        margin-left: -10px;
        padding: 2px 10px 2px 0;
        color: #fff;
    }
    &-label {
        color: $fontColor-4;
        margin: 0 20px 0 0;
    }
    .title {
        font-weight: $fontWeightMedium;
    }
    .content {
        color: $fontColor-3;
    }
    .info-item {
        flex: 0 0 33.333%;
        max-width: 33.333%;
    }
}
