.product-guide-header-box {
    position: relative;
    width: 240px;
    height: 240px;
    border-radius: 12px;
    overflow: hidden;
    img {
        width: 100%;
        height: auto;
    }
}

.product-guide-content-box {
    position: relative;
    width: 100%;
    min-height: 500px;
    max-width: 1200px;
    margin: 0 auto;
    img {
        width: 100%;
        height: auto;
    }
}

.product-gift-img-label {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 12px;
    overflow: hidden;
    img {
        width: 100%;
        height: auto;
    }
}
