$WhereSidesIsOne: (
    't': 'top',
    'b': 'bottom',
    'r': 'right',
    'l': 'left',
    'x': (
        'right',
        'left',
    ),
    'y': (
        'top',
        'bottom',
    ),
);

// padding
.p {
    @each $i, $sp in $spacers {
        &-#{$i} {
            padding: $sp !important;
        }
    }
    @each $name, $value in $WhereSidesIsOne {
        &#{$name} {
            @if $name == 'x' or $name == 'y' {
                @each $i, $sp in $spacers {
                    &-#{$i} {
                        @each $value2 in $value {
                            padding-#{$value2}: $sp !important;
                        }
                    }
                }
            } @else {
                @each $i, $sp in $spacers {
                    &-#{$i} {
                        padding-#{$value}: $sp !important;
                    }
                    &-auto {
                        padding-#{$value}: auto !important;
                    }
                }
            }
        }
    }
}

// margin
.m {
    @each $i, $sp in $spacers {
        &-#{$i} {
            margin: $sp !important;
        }
    }
    @each $name, $value in $WhereSidesIsOne {
        &#{$name} {
            @if $name == 'x' or $name == 'y' {
                @each $i, $sp in $spacers {
                    &-#{$i} {
                        @each $value2 in $value {
                            margin-#{$value2}: $sp !important;
                        }
                    }
                }
                &-auto {
                    @each $value2 in $value {
                        margin-#{$value2}: auto !important;
                    }
                }
            } @else {
                @each $i, $sp in $spacers {
                    &-#{$i} {
                        margin-#{$value}: $sp !important;
                    }
                    &-auto {
                        margin-#{$value}: auto !important;
                    }
                }
            }
        }
    }
    &t-label {
        margin-top: 2.854rem;
    }
}
