

.read-text-field {
    &-label {
        font-size: 1.4rem;
        line-height: 1.736em;
        color: $fontColor-3;
        padding: 0;
        margin-bottom: 0;
    }
    &-text {
        min-height: 4.1rem;
        line-height:2.4rem;
        padding: .85rem 1.4rem;
        background-color: $bg-light;
        border-radius: $shapeBorderRadius;
        margin-bottom: 0;
        //white-space: pre;
    }
}
