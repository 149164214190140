// 頁首 排版
.page-header {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 0.8rem;
    > #{&}-title {
        line-height: $titleLineHeight;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: $fontWeightBold;
    }
    .btn-group {
        margin-left: auto;
    }
}


/*
    頁首以 tabs 顯示
 */
.page-tabs {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tab-root {
        color: $fontColor-2;
        &:not(.tab-selected)  {
            opacity: .6;
        }
        &:hover {
            opacity: 1;
            &:not(.tab-selected) {
                color: $fontColor-2
            }
        }
    }
}

.all-checkbox-header {
    padding-left: 1.2rem;
}
