.counter-box {
    width: 100%;
    padding: 1.2rem 1.6rem;
    text-align: center;
    border-radius: $shapeBorderRadius;
    background-color: $bg-light;
    &-title {
        font-size: $body2Size;
        color: $fontColor-3;
        margin-bottom: 1rem;
        &.tip {
            font-size: 0;
            cursor: pointer;
            > .svg-icon-root {
                vertical-align: bottom;
            }
            > span {
                display: inline-block;
                line-height: 20px;
                height: 20px;
                margin-right: 2px;
                font-size: $body2Size;
                color: $fontColor-3;
            }
        }
    }
    &-number {
        font-size: $h4FontSize;
        font-weight: $fontWeightBold;
        padding: 0;
    }

    &-xl {
        .number {
            font-size: $h3FontSize;
            margin: 0;
        }
    }
    
    &-sm {
        padding: .6rem .8rem;
        .counter-box-title {
            font-size: $captionSize;
            margin: 0;
        }
        .counter-box-number {
            font-size: $h6FontSize;
            margin: 0;
        }
    }
}
