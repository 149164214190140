.upload-box {
    width: 100%;
    position: relative;
    border: 2px dashed lighten($primaryColorMain, 30%);
    border-radius: 1rem;
    background-color: lighten($primaryColorMain, 40%);
    cursor: pointer;
    .file-input {
        opacity: 0;
        position: absolute;
    }
    &-drop-enter {
        border: 2px dashed darken($primaryColorMain, 30%);
        border-radius: 1rem;
        background-clip: content-box;
    }
    &-wrap {
        position: relative;
        width: 100%;
        padding-top: 75%;
        * {
            pointer-events: none; // 阻斷子層所有dropleave事件
        }
    }
    &-content {
        width: 70%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -48%);
    }
    &-close-icon {
        position: absolute;
        opacity: 1;
        right: 20px;
        top: -12px;
        pointer-events: all;
    }
    .img-wrap {
        position: relative;
        display: block;
        margin-bottom: 1.6rem;
        pointer-events: all;
    }
    .img-link {
        margin-bottom: 1.6rem;
        .typography-root {
            margin-bottom: 0;
        }
        .img-wrap {
            margin-bottom: 0;
        }
    }
    &-img {
        width: 44%;
        display: block;
        margin: 0 auto 1rem;
        .img-wrap & {
            width: 24%;
        }
    }
    &-filename {
        word-break: break-all;
        word-wrap: break-word;
    }
    .desc {
        color: $primaryColorDarken;
        font-size: 1.4rem;
        background-color: rgba(black, 0.1);
        padding: 0.4rem 0.8rem;
    }
}
