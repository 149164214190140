// 文書提醒按鈕
.notice-card {
    position: relative;
    display: flex;
    margin-bottom: 1.6rem;
    > * {
        padding: 1.6rem;
    }
    > :first-child {
        padding-top: 0;
    }
    &-img {
        flex: 0 0 16rem;
        max-width: 16rem;
    }
    .control {
        .form-control-label-text {
            font-size: $h6FontSize;
            font-weight: $fontWeightMedium;
            margin-top: 6px;
            @media (min-width: $breakpoint-lg) {
                margin-top: 5px;
            }
        }
    }
    .list {
        padding: 0;
        &-item {
            line-height: $lineHeight;
            padding: .6rem 0 .6rem 3rem;
            > .form-control-label {
                align-items: flex-start;
                > .typography-root {
                    padding-top: 0.9rem;
                }
            }
        }
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        @include mxFakeDashed();
        width: calc(100% - 16rem);
    }
}
