.search-bar-light {
    padding: 20px;
    background-color: $bg-light;
    border-radius: $paperBorderRadius;
}

.cabinet-state-content {
    border-radius: $paperBorderRadius;
    .field-label {
        color: $fontColor-2;
    }
}
.rent-cabinet-card {
    position: relative;
    //padding: 2rem;
    border: 1px solid $borderColor-2;
    border-radius: $paperBorderRadius;
    &.quit {
        opacity: 0.7;
        background-color: $bg-light;
        img {
            filter: grayscale(1);
        }
    }
    &-top {
        padding: 2rem;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            @include mxFakeDashed($gray-400, 6px);
        }
    }
    &-bottom {
        padding: 2rem;
    }
    //&-state {
    //    &.chip-root {
    //        position: absolute;
    //        top: 6rem;
    //        right: 0;
    //        border-radius: 3rem 0 0 3rem;
    //        padding: 0 1rem;
    //    }
    //}
    > .icon-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: $mobileStepperZIndex;
    }
    .order-no {
        font-size: $body2Size;
        color: $fontColor-3;
        margin-bottom: 0;
        > span {
            margin-left: 4px;
        }
    }
    .title-wrap {
        width: calc(100% - 5rem);
        display: flex;
        justify-content: space-between;
        .title {
            font-size: $h6FontSize;
            font-weight: $fontWeightMedium;
            > :first-child {
                margin-right: 8px;
            }
        }
        > .price {
            font-size: $h5FontSize;
            font-weight: $fontWeightBold;
            color: $primaryColorMain;
            .unit {
                font-size: $body2Size;
                margin-left: 2px;
            }
        }
    }
    .desc {
        line-height: 1.4;
    }
    .memo {
        display: -webkit-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        > p {
            white-space: normal;
            font-size: $body2Size;
            color: $fontColor-3;
        }
    }
    .info-item {
        &:not(&:last-child) {
            margin-bottom: 1rem;
        }
        h6,
        p {
            font-size: $body2Size;
            padding-bottom: 0;
            margin-bottom: 0;
        }
        h6 {
            color: $fontColor-3;
            margin-bottom: 0;
        }
    }
}

.iframe-wrap {
    overflow: hidden;
    > iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}
