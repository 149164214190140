.signature-dialog-canvas-container {
    display: block;
    width: 100%;
    height: 500px;
    border-radius: .6rem;
    background-color: gray(50);
}

.signature-dialog-action{
    padding: 0;
    width: 50px;
    @at-root #{&}-button{
        width:100%;
        height:100%;
        border-radius: unset;
    }
}
