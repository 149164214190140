$DURATION: 550;

@mixin mxRipple($str) {
    &#{$str}-visible {
        opacity: 0.3;
        transform: scale(1);
        animation-name: rippleEnterKeyframe;
        animation-duration: $DURATION + ms;
        animation-timing-function: $easeInOut;
    }

    &#{$str}-pulsate {
        animation-duration: $durationShorter + ms;
    }

    #{$str}-child {
        opacity: 1;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: currentColor;
    }
}

.touch-ripple {
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    .ripple {
        opacity: 0;
        position: absolute;
        @include mxRipple(".ripple");

        .ripple-child.ripple-child-leaving {
            opacity: 0;
            animation-name: rippleExitKeyframe;
            animation-duration: $DURATION + ms;
            animation-timing-function: $easeInOut;
        }

        .ripple-child.ripple-child-pulsate {
            position: absolute;
            /* @noflip */
            left: 0px;
            top: 0;
            animation-name: ripplePulsateKeyframe;
            animation-duration: 2500ms;
            animation-timing-function: $easeInOut;
            animation-iteration-count: infinite;
            animation-delay: 200ms;
        }
    }
}

@keyframes rippleEnterKeyframe {
    0% {
        transform: scale(0);
        opacity: 0.1;
    }

    100% {
        transform: scale(1);
        opacity: 0.3;
    }
}

@keyframes rippleExitKeyframe {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes ripplePulsateKeyframe {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.92);
    }

    100% {
        transform: scale(1);
    }
}
