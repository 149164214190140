@import '../../mixins';
@import '../../functions';
@import '../../variables';

/*
  input disabled 狀態，內容需要清楚
 */
.order-dialog {
    .inputbase-input.inputbase-input-disabled {
        -webkit-text-fill-color: $fontColor-1;
    }
    .formlabel.formlabel-disabled {
        color: $fontColor-3;
    }
}

.order-type-box {
    display: flex;
    margin-bottom: 2.4rem;
}

.order-type-item {
    text-align: left;
    cursor: pointer;
    &:not(:last-child) {
        margin-right: 1.6rem;
    }
    &.active {
        color: white;
        background-color: $primaryColorMain;
        > .counter-box-title {
            color: white;
        }
        > .counter-box-number {
            color: white;
        }
    }
}

.petty-money-box {
    border-radius: $paperBorderRadius;
    .amount {
        padding: 0;
        margin-bottom: 1.6rem;
        color: $primaryColorMain;
        font-weight: $fontWeightBold;
        line-height: 4rem;
    }
    &-btn {
        float: right;
    }
}

.questionnaire-box {
    padding: 2rem;
    border-radius: $shapeBorderRadius;
    background-color: $infoColorLight;
    border: 1px dashed $infoColorMain;
    .questionnaire-title {
        line-height: 2.4rem;
        font-size: $body1Size;
        color: $infoColorMain;
        margin-top: 0;
        margin-bottom: 2rem;
        .svg-icon-root {
            fill: $infoColorMain;
            margin-right: 1.2rem;
        }
    }
}

.products {
    padding-top: 3.2rem;
    &-item {
        padding-bottom: 1.6rem;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            @include mxFakeDashed();
            width: calc(100% + 32px);
        }
        .close {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, -100%);
            transition: 400ms;
        }
    }
}

.product-menu {
    &-item {
        padding: 1.2rem;
        + #{&} {
            border-top: 1px solid $borderColor-2;
        }
    }
}

.order-history {
    &-item {
        position: relative;
        padding-left: 3rem;
        padding-bottom: 3rem;
        &:not(:last-child) {
            &::before {
                content: '';
                position: absolute;
                top: 2.5rem;
                left: 0.6rem;
                width: 1px;
                height: calc(100% - 2.6rem);
                border-radius: 2px;
                background-color: gray(100);
            }
        }
        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0.5rem;
            width: 0.8rem;
            height: 0.8rem;
            border: 3px solid $infoColorMain;
            border-radius: 0.8rem;
            background-color: white;
        }
        &-title {
            margin-top: 0;
            margin-bottom: 0.4rem;
        }
        &-subtitle {
            margin-top: 0;
            margin-bottom: 0;
            color: $fontColor-4;
        }
    }
}

.order-info-table {
    th,
    td {
        padding: 0.8rem 0px;
        border-bottom-width: 0;
    }
    tr {
        > :nth-child(1) {
            width: 30%;
            color: $fontColor-3;
        }
    }
}

.cart-content {
    .list {
        padding-right: 1.2rem;
        .total {
            span {
                font-weight: $fontWeightBold;
            }
        }
    }
}

.shopping-list {
    &:not(.return) {
        .table-cell {
            vertical-align: top;
        }
    }
}

/*
  折讓
 */
.return-qty {
    color: $errorColorMain;
    margin-bottom: 0.8rem;
    .svg-icon-root {
        font-size: $fontSize;
        margin-right: 1.6rem;
        color: $errorColorMain;
    }
}

.read-only-cart-table {
    tr {
        td,
        th {
            vertical-align: top;
        }
    }
    .line-height-60 {
        line-height: 6rem;
    }
    .product-box {
        &-desc {
            max-width: 20rem;
        }
        &-title {
            white-space: break-spaces;
        }
    }
}

.tool-btn-group {
    .button-base {
        color: $fontColor-3;
        font-weight: $fontWeightRegular;
        background-color: $bg-light;
        &:not(&:last-child) {
            margin-right: 6px;
        }
        .svg-icon-root {
            color: $fontColor-3;
        }
        &:hover {
            color: white;
            background-color: $secondaryColorMain;
            .svg-icon-root {
                color: white;
            }
        }
    }
}

.batch-operation-box {
    margin-bottom: 1rem;
    &-control {
        text-align: center;
        padding-right: 1.6rem;
        margin-right: 0;
        margin-left: 1.6rem;
    }
    &-menu {
        padding-left: 0.4rem;
        position: relative;
        .button-base {
            color: $fontColor-3;
            font-weight: $fontWeightRegular;
            background-color: gray(100);
            .svg-icon-root {
                color: $fontColor-3;
            }
        }
    }
}

.checklist {
    &-wrap {
        max-height: 60rem;
        overflow: auto;
    }
    &-fixed-area {
        position: sticky;
        right: 0;
        bottom: -1px;
        background-color: white;
    }
}

/*
  tables
 */

.cart-table {
    .table-row {
        .table-cell {
            min-width: 7rem;
        }
    }
    &-item {
        .form-control-root {
            width: 10rem;
        }
    }
    .product-box {
        &-desc {
            max-width: 30rem;
        }
        &-title {
            white-space: break-spaces;
        }
    }
}

.table-cell-controller {
    &:not(.no-control) {
        cursor: pointer;
        &:hover {
            color: $secondaryColorMain;
        }
    }
}

/*
  紅利
 */
.bonus-cart-table {
    .cart-table-item {
        .form-control-root {
            width: 14rem;
        }
    }
}

/*
  新增收入商品選單
 */
.shopping-menu {
    &-container {
        background-color: $bg-light;
        .shopping-menu-side-bar {
            position: absolute;
            top: 0;
            left: 0;
            width: 250px;
            height: 100%;
            background-color: #5b2a7a;
            overflow-y: auto;
            .logo {
                padding: 3rem 2rem;
                text-align: center;
                filter: brightness(100);
            }
            .menu {
                padding: 0;
                > .list-item-button {
                    &:not(:first-child) {
                        border-top: 1px solid $borderColor-2;
                    }
                }

                .list-item-button {
                    &.is-current {
                        background-color: darken($primaryColorMain, 10);
                    }
                    &:hover {
                        background-color: darken($primaryColorMain, 20);
                    }
                }

                .list-item-text {
                    color: white;
                }

                .svg-icon-root {
                    color: white;
                }
            }
        }
    }
    &-content {
        margin-left: 250px;
        padding-bottom: 100px;
        .products {
            padding-right: 7rem;
            padding-left: 3rem;
            .product-box {
                position: relative;
                padding: 1.5rem 7.5rem 1.5rem 1.5rem;
                background-color: white;
                border-radius: $shapeBorderRadius;
                height: 100%;
                cursor: pointer;
                &.is-select {
                    box-shadow: 0 0 0 2px $primaryColorMain;
                }
                .stock {
                    right: -4px;
                    left: unset;
                    top: 20px;
                }
                .price {
                    position: absolute;
                    right: 20px;
                    bottom: 15px;
                }
            }
        }
    }
    &-footer {
        position: absolute;
        bottom: 0;
        width: calc(100% - 250px);
        padding: 1.5rem 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        box-shadow: 0 -4px 6px rgba(black, 0.1);
        .btn-checkout {
            min-width: 350px;
        }
        .btn-close {
            background-color: gray !important;
        }
    }
}

.product-box.fee-item {
    align-items: center;
}

.MuiModal-root {
    &.shopping-menu-dialog {
        .MuiPaper-root {
            height: calc(100% - 64px);
        }
        .dialog-close-btn {
            position: absolute;
            right: 20px;
            top: 20px;
            background-color: rgba(black, 0.5);
            border-radius: 30px;
            z-index: $modalZIndex;
            .button-base {
                padding: 6px;
            }
        }
        .shopping-menu-container {
            &.MuiDialogContent-root {
                padding: 0;
            }
        }
    }
}

/*
  欠費單
 */
.credit-order-card {
    .card-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.2rem 2.4rem 1.2rem 1.6rem;
        .desc {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            > .button-bas {
                margin-right: 1rem;
            }
        }
    }
    &-title {
        font-size: $fontSize;
        font-weight: $fontWeightMedium;
        margin: 0;
        > span {
            font-size: $body2Size;
            font-weight: $fontWeightRegular;
            margin-left: 1rem;
        }
    }
    &-price {
        font-size: $fontSize;
        font-weight: $fontWeightBold;
        margin: 0;
    }
}

/*
  批次扣款名單
 */
.bulk-debit-table {
    .table {
        position: relative;
        .table-head {
            position: sticky;
            top: 0;
            z-index: 1;
            background-color: white;
        }
    }
    tr.disabled-row,
    tr.disabled-row:hover {
        opacity: 0.5;
        background-color: gray(100);
    }
    .table-container {
        max-height: 54rem;
    }
    .item-list {
        > li + li {
            margin-top: 0.4rem;
        }
    }
    .payment-card-content {
        .title {
            padding: 0;
            margin-bottom: 0.4rem;
        }
        .sub-title {
            margin: 0;
        }
    }
}

.bulk-debit-content {
    .report-btn-group {
        float: left;
        + .scroll-arrow-container {
            clear: both;
        }
    }
}

// 進行批次作業的訊息框
.bulk-controller-box {
    position: relative;
    padding: 2rem;
    border-radius: $paperBorderRadius;
    background-color: $bg-light;
    .btn-group {
        text-align: right;
    }
    &-container & {
        clear: both;
    }
}

.import-ach-box {
    max-width: 35rem;
    margin-right: auto;
    margin-left: auto;
}

.reset-order-invoice-dialog {
    .card-root {
        box-shadow: none;
        border: 1px solid $borderColor-2;
    }
}
