$_tabs: ".tabs";
$_tab: ".tab";

#{$_tabs} {
    &-root {
        position: relative;
    }
    &-bottom-line {
        position: relative;
        &::after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }
    }
    &-indicator {
        z-index: $mobileStepperZIndex;
    }
}

#{$_tab}-root {
    color: $fontColor-2;
    &:hover {
        color: $primaryColorMain;
    }
    &.tab-selected,
    &.active {
        color: $primaryColorMain;
        font-weight: $fontWeightMedium;
        .svg-icon-root {
            fill: $primaryColorMain;
        }
    }
}
