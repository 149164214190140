.points-redeem-box {
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    &.add {
        background-color: $successColorMain;
    }
    &.minus {
        background-color: $errorColorMain;
    }
}
