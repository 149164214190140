.inputbase-root {
    @include mxVariants("body1");
    line-height: 1.4375em; // 23px
    box-sizing: border-box; // Prevent padding issue with fullWidth.
    position: relative;
    cursor: text;
    display: inline-flex;
    align-items: center;
    background: #fff;
    &.inputbase-root-disabled {
        color: $textDisabledColor;
        background-color: $actionDisabledBackground;
        cursor: default;
    }
    // multiline 多行的意思 用在 textarea
    &.inputbase-root-multiline {
        padding: 4px 0 5px;
        &.inputbase-root-small {
            padding-top: 1.6rem;
        }
    }
    &.inputbase-root-fullWidth {
        width: 100%;
    }
    &.outlinedinput-root-endAdornment {
        .input-adornment {
            .icon-button {
                margin-right: -10px;
                padding: 4px;
            }
        }
    }
}

.inputbase-input {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em; // Reset 23pxthe native input line-height
    margin: 0; // Reset for Safari
    -webkit-tap-highlight-color: transparent;
    display: block;
    // Make the flex item shrink with Firefox
    min-width: 0;
    width: 100%; // Fix IE11 width issue

    &:focus {
        outline: 0;
    }
    &:invalid {
        box-shadow: none;
    }
    &.inputbase-input-disabled {
        opacity: 1; // Reset iOS opacity
        -webkit-text-fill-color: $textDisabledColor; // Fix opacity Safari bug
        &[class*="select"] {
            cursor: no-drop;
        }
    }
    &.inputbase-input-size-small {
        padding-top: 1px;
    }
    &.inputbase-input-multiline {
        height: auto;
        resize: none;
        padding: 0;
    }
    &[type="search"] {
        -moz-appearance: textfield;
        -webkit-appearance: textfield;
    }
}
