$_appointmentTimeList: '.appointment-time-list';

#{$_appointmentTimeList}-time-item {
    position: sticky;
    top: 180px;
    z-index: 5;
    > * {
        flex-shrink: 0;
    }
}

#{$_appointmentTimeList}-time-period {
    display: flex;
    align-items: center;
    background: $warningColorMain;
    color: #fff;
    padding: 10px 16px;
    border-radius: 4px;
}

#{$_appointmentTimeList}-circle-decorate,
#{$_appointmentTimeList}-line-decorate {
    position: relative;
    display: inline-block;
    width: 40px;
}

#{$_appointmentTimeList}-circle-decorate {
    &::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: gray(200);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

#{$_appointmentTimeList}-line-decorate {
    &::after {
        content: '';
        position: absolute;
        width: 4px;
        background: gray(200);
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

// #{$_appointmentTimeList}-context #{$_appointmentTimeList}-content:not(:last-child) {
//     #{$_appointmentTimeList}-line-decorate::after {
//         bottom: -52px;
//     }
// }

#{$_appointmentTimeList}-dotted-line {
    @include mxFakeDashed();
    margin: 0 16px;
    flex-shrink: 1;
}

#{$_appointmentTimeList}-fram-decorate {
    border: 1px solid $borderColor-2;
    border-radius: 4px;
    padding: 0.4rem 1rem;
    & + #{$_appointmentTimeList}-fram-decorate {
        margin: 12px 0 0 0;
    }
}

#{$_appointmentTimeList}-cellphone {
    padding: 8px 16px 0;
}

#{$_appointmentTimeList}-light-text {
    color: gray(500);
}

#{$_appointmentTimeList}-name-text {
    font-weight: $fontWeightMedium;
}

#{$_appointmentTimeList}-nickname-text {
    font-size: 1.4rem;
    font-weight: $fontWeightMedium;
    color: gray(500);
}

#{$_appointmentTimeList}-memberno-text {
    font-weight: $fontWeightMedium;
}

#{$_appointmentTimeList}-data {
    width: calc(100% - 40px);
    padding-bottom: 6px;
}

#{$_appointmentTimeList}-notes {
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

#{$_appointmentTimeList}-contact-notes {
    max-height: 60px;
    padding: 4px 16px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

#{$_appointmentTimeList}-action {
    padding: 8px 0 0 14px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        bottom: 5px;
        background: $borderColor-2;
        width: 1px;
    }
}

.appointment-time-list-context {
    position: relative;
}

.appointment-time-list-header::before {
    position: absolute;
    content: ' ';
    left: 0;
    right: 0;
    top: -13px;
    padding-top: 25px;
    background-color: inherit;
}

.appointment-time-list-context .daily-memo {
    position: relative;
    background: $bg-light;
    .daily-memo-text {
        padding: 5px 12px 5px 0;
        line-height: 1.5;
    }
    .dialy-memo-text-toggle {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
    }
}

.appointment-time-list-context .daily-memo-toggle {
    position: absolute;
    top: 0;
    right: 0;
}
