.membership-record {
  &-box-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  &-card {
    position: relative;
    background-color: $bg-light;
    border: 1px solid $borderColor-2;
    border-radius: $shapeBorderRadius;
    padding: 1.2rem 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &-img {
      cursor: pointer;
      display: block;
      max-width: 6rem;
      border-radius: 50%;
      background-color: white;
      box-shadow: map-get($shadows, 4);
      padding: 1.2rem;
      margin-right: 2.4rem;

      > img {
        width: 100%;
        height: auto;
        vertical-align: middle;
      }
    }

    &-content {
      flex-grow: 1;

      .title {
        font-weight: $fontWeightMedium;
      }

      .date-range {
        margin-right: 2rem;
      }
    }
  }

  &-info {
    display: flex;

    > p {
      margin: 0;
      flex: 1 1 auto;
      padding: 0.8rem 1rem;

      .label {
        color: $fontColor-3;
        margin-right: 2rem;
      }
    }

    .coach {
      margin-left: auto;
      text-align: right;
    }
  }
}

.membership-suspend-bg-gray {
  opacity: .75;

  .title {
    color: $gray-800;
    vertical-align: middle;

    &:after {
      content: '已停扣';
      color: $gray-500;
      font-size: small;
      margin-left: 5px;
      padding: 1px 3px;
    }
  }

  .label {
    color: $gray-700;
  }

  .date-range, .text {
    color: $gray-500;
  }

  .membership-record {
    &-card {
      background-color: $gray-100;
      border: 1px solid $gray-200;

      &-img > img {
        filter: grayscale(1);
      }
    }
  }

  .chip-root {
    color: $gray-500;
    background-color: $gray-200;
  }
}
