.collapse-root {
    height: 0;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &.collapse-horizontal {
        height: auto;
        width: 0;
        transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    &.collapse-entered {
        height: auto;
        overflow: visible;
        &.collapse-horizontal {
            width: auto;
        }
    }
    &.collapse-exited {
        visibility: hidden;
    }
}

.collapse-wrapper {
    display: flex;
    width: 100%;
    &.collapse-horizontal {
        width: auto;
        height: 100%;
    }
}

.collapse-wrapper-inner {
    width: 100%;
    &.collapse-horizontal {
        width: auto;
        height: 100%;
    }
}
