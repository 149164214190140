$_formControl: ".form-control";
#{$_formControl} {
    &-align-horizontal {
        display: flex;
        flex-direction: row;
        > .formlabel {
            padding-right: 2rem;
            > span {
                line-height: 4.2rem;
                &:not([class*="asterisk"]) {
                    color: $fontColor-1;
                    font-size: $fontSize;
                }
            }
        }
        &#{$_formControl}-label-wrap > .formlabel {
            flex-shrink: 0;
        }
    }
    &-y-between {
        justify-content: space-between;
    }
}

.field {
    &-y-between {
        justify-content: space-between;
    }
}
