.workout-timeline {
    width: calc(100% - 110px);
    padding: 6rem 2.4rem 4rem;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    background-color: white;
    &::after {
        content: "";
        display: table;
        clear: both;
    }
    &-box {
        position: relative;
    }
    &-wrap {
        display: flex;
        justify-content: flex-start;
    }
    &-item {
        --levelColor: #{$gray-300};
        flex: 0 0;
        height: 1rem;
        position: relative;
        background-color: var(--levelColor);
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
        }
        &::after {
            content: "";
            width: 10px;
            height: 10px;
            border: 2px solid white;
            background-color: var(--levelColor);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(50%, -50%);
            box-shadow: map-get($shadows, 1);
            z-index: 1;
        }
        .name {
            font-size: $subtitle2Size;
            margin-bottom: 1.2rem;
            color: $fontColor-3;
            position: absolute;
            bottom: 100%;
            right: 0;
            transform: translateX(50%);
            text-align: center;
            white-space: nowrap;
        }
        .text {
            margin-bottom: 0;
            color: $fontColor-2;
            position: absolute;
            top: calc(100% + 1.2rem);
            right: 0;
            transform: translateX(50%);
            text-align: center;
        }
    }
    &-controller {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        .icon-button {
            padding: 1.2rem;
            background-color: $bg-light;
            .svg-icon-root {
                fill: $fontColor-2;
            }
            &:hover {
                background-color: $bg-light-active;
            }
            &.icon-button-disabled {
                .svg-icon-root {
                    fill: gray(300);
                }
            }
        }
        &.next {
            right: 0;
        }
        &.prev {
            left: 0;
        }
        .text {
            color: $fontColor-4;

        }
        &:hover {
            .text {
                color: $fontColor-3;
                transform: scale(1.1);
            }
        }
    }
}


