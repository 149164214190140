.dropdownlist {
    position: relative;
    padding: 0 !important;
    #{&}-placeholder,
    #{&}-value {
        padding: 12px 16px;
        width: 100%;
        height: 100%;
        // line-height: 1.9;
    }
    #{&}-placeholder {
        color: gray(400);
    }
    #{&}-arrow {
        position: absolute;
        right: 8px;
        top: 50%;
        height: 20px;
        width: 20px;
        font-size: 20px;
        margin-top: -10px;
        color: gray(300);
    }
    select {
        position: absolute;
        top: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}
