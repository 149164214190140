@import "../mixins/mixin";
@import "../functions/functions";
@import "../variables/index";

.not-found-pdf-page {
    position: relative;
    min-height: 100vh;
    background: #fff url('../../images/bg-dot.png') no-repeat left center/cover;
    padding: 3rem;
    .container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        max-width: 1200px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 3rem;
    }
    .img-action {
        flex: 0 0 22%;
        background: url('../../images/css_sprites1.png') no-repeat top left / 100%;
        transition: all 2s;
        animation: action 1s steps(4) both infinite;
        &::after {
            content: '';
            display: block;
            width: 100%;
            padding-top: 200.668896%;
        }
    }
    .content {
        flex: 1 1 52%;
        max-width: 52%;
        padding-left: 9rem;
        .title {
            font-size: 5rem;
            color: $fontColor-1;
            position: relative;
            margin: 0 0 4rem 0;
            &::before {
                content: '';
                position: absolute;
                left: -3.2rem;
                top: 1.4rem;
                width: 1.5rem;
                height: 4.4rem;
                background-image: linear-gradient(30deg, #FF00FF, #662D8C);
                transform: skew(12deg);
            }
        }
        .sub-title {
            font-size: 2.8rem;
            color: $fontColor-1;
            margin-bottom: 2rem;
        }
        .list {
            font-size: 2rem;
            margin-left: 2rem;
            list-style: disc;
            li {
                margin-bottom: 1.2rem;
                line-height: $lineHeight;
            }
        }
    }
}


@keyframes action {
    100% {
        background-position: 0 100%
    }
}
