$_tab: ".tab";
#{$_tab}-root {
    @include mxVariants("button");
    max-width: 360px;
    min-width: 90px;
    position: relative;
    min-height: 48px;
    flex-shrink: 0;
    padding: 12px 16px;
    overflow: hidden;
    white-space: normal;
    text-align: center;
    line-height: 1.25;
    &#{$_tab}-icon-start {
        flex-direction: row;
    }
    &#{$_tab}-icon-end {
        flex-direction: row-reverse;
    }
    @at-root #{&}-icon#{&}-label {
        min-height: 50px;
        // padding-top: 9px;
        // padding-bottom: 9px;
        &#{$_tab}-icon-start {
            & > *:first-child {
                margin-right: 8px;
            }
        }
        &#{$_tab}-icon-end {
            & > *:first-child {
                margin-left: 8px;
            }
        }
    }
    @at-root #{&}-primary {
        color: $textPrimaryColor;
        &#{$_tab}-selected {
            color: $primaryColorMain;
        }
        &#{$_tab}-disabled {
            color: rgba($textPrimaryColor, $actionDisabledOpacity);
        }
    }
    @at-root #{&}-secondary {
        color: $textPrimaryColor;
        &#{$_tab}-selected {
            color: $secondaryColorMain;
        }
        &#{$_tab}-disabled {
            color: rgba($textPrimaryColor, $actionDisabledOpacity);
        }
    }
    @at-root #{&}-full-width {
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: 0;
        max-width: none;
    }
    &#{&}-wrapped {
        font-size: 1.2rem;
    }
}
