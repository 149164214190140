.employees {
    .layout-container {
        background-color: $bg-light;
        border-radius: $shapeBorderRadius;
        padding: 20px;
        .layout-title {
            color: $primaryColorMain;
            margin: 0;
        }
    }

    .table-head .table-row {
        background-color: $bg-light;
    }

    .table-cell + .table-cell {
        box-shadow: -1px 0px 0px 0px $gray-200;
    }
}

.add-other-schedule-mx {
    margin-left: 50px !important;
    margin-right: 50px !important;
}

.add-other-schedule-max-height {
    min-height: 60px;
    max-height: 250px;
}

ul.employee-other-matter {
    width: 35.6rem;
}

.employee-other-matter-text {
    overflow: hidden;
    width: 15rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: $fontWeightMedium;
}

.employee-other-matter-input {
    width: 15rem;
}

.field-employee {
    .form-control-label-text {
        white-space: nowrap;
    }
}
