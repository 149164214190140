$_svgIcon: '.svg-icon';
#{$_svgIcon}-root {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    color: gray(400);
    flex-shrink: 0;
    transition: getTransition(fill);
    @at-root #{$_svgIcon}-small {
        font-size: 20px;
    }
    @at-root #{$_svgIcon}-medium {
        font-size: 26px;
    }
    @at-root #{$_svgIcon}-large {
        font-size: 35px;
    }
    @each $key, $value in $colorMainMap {
        @at-root #{$_svgIcon}-color-#{$key} {
            color: $value;
        }
    }
    #{$_svgIcon}-disabled {
        color: rgba(0, 0, 0, 0.26);
    }
}
