.member-avatar {
    position: relative;
    .state {
        width: 26%;
        min-width: 1.2rem;
        max-width: 2rem;
        border: 2px solid white;
        border-radius: 50%;
        background-color: $primaryColorMain;
        position: absolute;
        right: 14%;
        bottom: 14%;
        transform: translate(50%, 50%);
        &::after {
            content: "";
            display: block;
            width: 100%;
            padding-top: 100%;
        }
    }

}
