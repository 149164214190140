.todo-list {
  padding: 0;

  &-item {
    padding: 0;
    align-items: flex-start;

    .list-item-button {
      align-items: flex-start;

      &:hover {
        background-color: inherit;
      }
    }

    .list-item-icon + .list-item-icon {
      padding-top: 0.7rem;
    }

    .list-item-text {
      flex: 1 1 auto;
      margin-top: 0.5rem;
      margin-bottom: 0;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      position: relative;

      > .list-item-text-primary {
        font-size: $body1Size;
      }
    }

    // > .icon-button:last-child {
    //     margin-top: 0.8rem;
    // }
    &-complete {
      .list-item-text {
        opacity: 0.5;
        text-decoration: line-through;
        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: 50%;
        //     left: 0;
        //     width: 100%;
        //     height: 1px;
        //     background-color: $fontColor-2;
        //     transform: translateY(-50%);
        // }
      }
    }
  }

  &-box {
    position: relative;

    &-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 1rem 0.6rem 0.8rem 2rem;
      border-bottom: 1px solid $gray-300;

      .icon {
        margin-right: 1rem;
      }
    }

    &-title {
      font-weight: $fontWeightMedium;
    }
  }

  &-controller {
    padding: 1.2rem;
    background-color: $primaryColorLight;

    .formlabel {
      color: $fontColor-2;
    }

    .inputbase-root {
      margin-right: 1.2rem;
    }

    &-btn {
      padding-right: 1rem;
      padding-left: 1rem;
      margin-top: 2.4rem;
    }
  }

  &-add {
    margin-left: auto;
  }
}
