@import "../../mixins";
@import "../../functions";
@import "../../variables";

.sports-record-content {
  .total {
    height: 100%;
  }
}

.time-line {
  --top: 1.6rem;
  padding-bottom: var(--top);
  &-item {
    --size: 1.2rem;
    position: relative;
    padding-left: 5rem;
    padding-right: 1rem;
    padding-bottom: 4rem;
    &::after {
      content: '';
      position: absolute;
      left: 1rem;
      top: var(--top);
      width: var(--size);
      height: var(--size);
      background-color: $gray-200;
      border-radius: var(--size);
    }
    &:not(:last-child) {
      &::before {
        content: '';
        position: absolute;
        left: 1.5rem;
        top: var(--top);
        width: 2px;
        height: 100%;
        background-color: $gray-200;
      }
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}

.contact-record-content {
  .page-header {
    padding-left: 2.4rem;
    padding-right: 1rem;
  }
  .contact-record-card {
    box-shadow: none;
    border: 1px solid $gray-200;
  }
}

.simple-content {
  &.dialog {
    .btn {
      white-space: nowrap;
      margin-top: 2.8rem;
    }
  }
}

// 會員管理>管理發票
.profile-invoice-card {
  position: relative;
  border: 1px solid $borderColor-2;
  border-radius: $paperBorderRadius;
  box-shadow: none;
  &-btn {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}

.main-orders {
  .main-order {
    margin-bottom: 3rem;
  }
}

.minor-orders {
  --size: 2rem;
  --decoration-line-color: #ffcc80;
  --top: 1.6rem;
  padding-bottom: var(--top);
  position: relative;
  padding-left: 0;
  &::before {
    content: '';
    position: absolute;
    left: 3rem;
    width: var(--size);
    height: var(--size);
    background-color: var(--decoration-line-color);
    border-radius: var(--size);
  }
}

.minor-order-item {
  padding-top: 4rem;
  padding-left: 7rem;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 5.2rem;
    left: 4rem;
    width: 2rem;
    height: 2px;
    background-color: var(--decoration-line-color);
    transform: translateY(-50%);
  }
  &::after {
    content: '';
    position: absolute;
    background-color: var(--decoration-line-color);
    border-radius: var(--size);
    transform: translateX(-50%);
  }
  &:not(&:first-child) {
    &::after {
      bottom: calc(100% - 52px);
      left: 4rem;
      width: 2px;
      height: 100%;
    }
  }
  &:first-child {
    &::after {
      bottom: calc(100% - 52px);
      left: 4rem;
      width: 2px;
      height: 52px;
    }
  }
}

.uncheckout {
  .counter-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-title {
      margin: 0;
    }
  }
}

.notify-item {
  + .notify-item {
    padding-top: 1.2rem;
    margin-top: 1.2rem;
    border-top: 1px solid $borderColor;
  }
}

.cycle-purchase-page {
  .main-effect-group {
    .card-header:not(:last-child) {
      border-bottom: 1px solid white;
    }
  }
}

.cycle-order-month-statistics-box {
  position: relative;
  padding: 1rem 0;
  &-controller {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    .icon-button {
      padding: 1.2rem;
      background-color: $bg-light;
      .svg-icon-root {
        fill: $fontColor-2;
      }
      &:hover {
        background-color: $bg-light-active;
      }
      &.icon-button-disabled {
        .svg-icon-root {
          fill: gray(300);
        }
      }
    }
    &.next {
      right: 3rem;
    }
    &.prev {
      left: 3rem;
    }
    .text {
      color: $fontColor-4;

    }
    &:hover {
      .text {
        color: $fontColor-3;
        transform: scale(1.1);
      }
    }
  }
}

.cycle-order-month-statistics-table {
  width: calc(100% - 14rem);
  margin-right: auto;
  margin-left: auto;
  .table-cell {
    min-width: 4.8rem;
    padding: 4px 12px;
    border-bottom-width: 0;
    text-align: center;
  }
  .table-cell-head {
    padding-top: 12px;
    font-weight: $fontWeightMedium;
    &.isSuspension, &.isQuit {
      border-radius: 24px 24px 0 0;
    }
    &.isSuspension {
      background-color: $warningColorLight;
    }
    &.isQuit {
      background-color: $errorColorLight;
    }
  }
  .table-cell-body {
    color: $fontColor-3;
    &.primary {
      color: $primaryColorMain;
    }
    &.isSuspension {
      color: $warningColorMain;
      background-color: $warningColorLight;
    }
    &.isQuit {
      color: $errorColorMain;
      background-color: $errorColorLight;
    }
  }
  .table-body {
    .table-row:last-child {
      .table-cell {
        &.isSuspension, &.isQuit {
          border-radius: 0 0 24px 24px;
        }
      }
    }
  }
  &-icon {
    font-size: 3.6rem;
  }
}

// 會員狀態的標題
.member-state {
  .simple-content-item-label {
    font-size: 1.6rem;
  }
}

