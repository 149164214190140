.workout-calendar-box {
    position: relative;
    &-calendar {
        width: 384px;
        header {
            .calendar {
                &-week {
                    background-color: #f4f6fa;
                }
            }
        }
        &.calendar {
            .paper {
                height: 100%;
            }
            .calendar-container {
                padding-top: 0;
            }
        }
        .calendar {
            &-week {
                > :last-child {
                    background-color: #f4f6fa;
                    border-radius: 0;
                }
            }
            &-day {
                cursor: default;
                position: relative;
                z-index: 1;
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    z-index: -1;
                }
                &.workout {
                    color: white;
                    cursor: pointer;
                    &::before {
                        background-color: $primaryColorMain;
                    }
                }
                &.measure {
                    &::before {
                        box-shadow: 0 0 0 4px $infoColorMain;
                    }
                }
            }
            &-today {
                color: $primaryColorMain !important;
                &::before {
                    border: 1px solid $primaryColorMain;
                }
                &.workout {
                    color: white !important;
                    &::before {
                        border-width: 0;
                        background-color: $secondaryColorMain;
                    }
                }
            }
            &-others {
                visibility: hidden;
            }
        }

        > .elevation-1 {
            box-shadow: none;
        }
    }
    &-controller {
        position: absolute;
        top: 2.6rem;
        transform: translateY(-50%);
        &.icon-button {
            padding: 1.2rem;
            background-color: $bg-light;
            .svg-icon-root {
                fill: $fontColor-2;
            }
            &:hover {
                background-color: $bg-light-active;
            }
            &.icon-button-disabled {
                .svg-icon-root {
                    fill: gray(300);
                }
            }
        }
        &.next {
            right: 0;
        }
        &.prev {
            left: 0;
        }
        &-box {
            position: relative;
            min-height: 4.8rem;
            .membership-progress-bar-box {
                width: calc(100% - 136px);
                margin-right: auto;
                margin-left: auto;
            }
        }
    }
}
