@import "../../mixins";
@import "../../functions";
@import "../../variables";

.order-info-card.card-root {
    .card-header {
        padding: 1.2rem 2rem;
        .card-header-content .card-header-content-title {
            font-size: 1.6rem;
        }
        &.bg-error-light {
            border-color: $errorColorMain;
            border-style: solid;
            border-width: 1px 1px 0 1px;
            border-radius: $paperBorderRadius $paperBorderRadius 0 0;
            + .card-content {
                border-color: $errorColorMain;
                border-style: solid;
                border-width: 0 1px 1px 1px;
                border-radius: 0 0 $paperBorderRadius $paperBorderRadius;
            }
        }
    }
}

.cycle-purchase-cart {
    .table-body {
        .table-row:last-child {
            .table-cell {
                border-bottom-width: 0;
            }
        }
    }
}

.tab-label {
     + .tab-label-qty {
         min-width: 24px;
         color: #fff;
         line-height: 24px;
         border-radius: 12px;
         background-color: #a45bc8;
         font-size: 1.5rem;
         margin-left: 4px;
    }
}