.badge-box {
    --color: #{$fontColor-1};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid var(--color);
    border-radius: $shapeBorderRadius;
    overflow: hidden;
    > p {
        margin: 0;
        padding: 0.4rem 1.5rem;
    }
    &-title {
        color: white;
        background-color: var(--color);
    }
    &-content {
        color: var(--color);
    }
    @each $name, $color in $colorMainMap {
        &-#{$name} {
            --color: #{$color};
        }
    }
}
