.leader-board-table {
  .table {
    .table-body {

      .table-row:nth-child(odd) {
        .table-cell {
          background-color: $bg-light;
        }
      }
    }
  }
}