@import "../../mixins";
@import "../../functions";
@import "../../variables";

/**
    使用在數據分析頁
 */


/**
    tables
 */
.daily-table, .monthly-table {
  box-shadow: none;

  .table {
    .table-head {
      .table-cell-head {
        background-color: $bg-light;
      }
    }

    .table-cell {
      line-height: 26px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-width: 1px 0 1px 0;

      &:first-child {
        padding-left: 2.4rem;
      }

      &:last-child {
        padding-right: 2.4rem;
      }
    }
  }

  .data-analysis {
    &-link {
      color: $secondaryColorMain;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.daily-table {
  .table {
    .total-row {
      .table-cell {
        font-weight: $fontWeightMedium;
        background-color: rgba($primaryColorMain, .1);
      }
    }

    .total-col {
      &.table-cell {
        border-right: 1px dashed $borderColor;
      }
    }
  }
}

.monthly-table {
  .table {
    .total-col {
      &.table-cell {
        border-left: 1px dashed $borderColor;
        background-color: rgba($primaryColorMain, .1);
      }
    }
  }
}


/**
    reminders
 */
.reminders {
  .sub-title {
    display: inline-block;
    padding: .6rem 1.6rem;
    background-color: $bg-light;
    border-radius: $shapeBorderRadius;
  }

  &-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    > .list-item {
      flex: 1 1 calc(50% - 1.5rem);
      max-width: calc(50% - 1.5rem);
      border-bottom: 1px solid $borderColor-2;

      &.list-item-gutters {
        padding-left: 0;
        padding-right: 0;
      }

      &:nth-child(even) {
        margin-left: 3rem;
      }
    }
  }
}


///**
//    ca 報表的員工評量表
//    evaluation-box
// */
//.evaluation-box {
//    .info-item {
//        text-align: center;
//        > .typography-root {
//            width: 11rem;
//            padding: .8rem 1.6rem;
//            margin: 0;
//            &:not(:first-child) {
//                border: 1px solid $borderColor-2;
//            }
//            &:first-child {
//                border-top-left-radius: $shapeBorderRadius;
//                border-bottom-left-radius: $shapeBorderRadius;
//                border-left: 1px solid $borderColor-2;
//                border-top: 1px solid $borderColor-2;
//                border-bottom: 1px solid $borderColor-2;
//                background-color: gray(100);
//            }
//            &:last-child {
//                border-top-right-radius: $shapeBorderRadius;
//                border-bottom-right-radius: $shapeBorderRadius;
//            }
//        }
//        &.text-info {
//            > .typography-root {
//                border-color: $infoColorMain;
//                &:first-child {
//                    background-color: $infoColorLight;
//                }
//            }
//        }
//    }
//    .ca-table {
//        .table {
//            border: 1px solid $borderColor-2;
//        }
//    }
//}

/**
   切換日/月
 */
.report-btn-group {
  .button-base {
    padding: .4rem 1rem;
    min-width: 7rem;
    font-size: 14px;
    color: $fontColor-3;
    background-color: $bg-light;
    border-color: $bg-light;

    &.current {
      color: white !important;
      background-color: $primaryColorMain !important;
      border-color: $primaryColorMain !important;
    }
  }
}


.counter-box {
  &.kpi-counter-box {
    padding: 1.6rem;

    .read-text-field {
      display: flex;
      align-items: center;
      margin-top: .8rem;

      &-label {
        margin-right: 1rem;
        white-space: nowrap;
      }

      &-text {
        width: 100%;
        padding: .6rem 1.4rem;
        min-height: 3.8rem;
        border: 1px solid $borderColor;
        background-color: white;
      }
    }

    &.is-valid {
      background-color: $successColorLight;
    }

    &.is-invalid {
      background-color: $errorColorLight;
    }

    .counter-box-title:not(.tip) {
      line-height: 20px;
      height: 20px;
    }
  }
}

.sales-wrap {
  overflow-x: auto;

  .grid-nowrap {
    flex-wrap: nowrap;

    > .MuiGrid-item {
      flex-shrink: 0;
    }
  }
}

.expect-drop-card {
  padding: 1.6rem;
  background-color: $bg-light;
  box-shadow: none;

  &-title {
    font-size: $body2Size;
    color: $fontColor-3;
  }

  .expect-drop-item {
    padding-top: 1rem;
    text-align: center;

    &-title {
      > p {
        margin-bottom: 0
      }

      font-size: $body2Size;
      color: $fontColor-3;
      margin-bottom: 0;
    }

    &-text {
      font-size: $h4FontSize;
      font-weight: $fontWeightBold;

      &.is-invalid {
        color: $errorColorMain;
      }

      &.is-invalid-link {
        color: $errorColorMain;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  
}

.member-rank-card {
  padding: 1.6rem;
  background-color: $bg-light;
  box-shadow: none;

  &-title {
    font-size: $body2Size;
    color: $fontColor-3;
  }

  .member-rank-item {
    padding-top: 1rem;
    text-align: center;

    &-title {
      font-size: $body2Size;
      color: $fontColor-3;
      margin-bottom: 0;
    }

    &-text {
      font-size: $h4FontSize;
      font-weight: $fontWeightBold;
    }
  }
}

.kpi-text-filed {
  label {
    > p {
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 1.6rem;
      color: $fontColor-1;

      > .typography-root {
        font-size: 1.8rem;
        color: $fontColor-1;
      }
    }
  }
}

.hc-kpi-text-filed {
  .formlabel {
    p {
      margin-bottom: 0;
    }
  }
}



