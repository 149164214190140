.product-box {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    &-img {
        width: 6rem;
        height: auto;
        vertical-align: middle;
        margin-right: 1.5rem;
    }
    &-title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-top: 0;
        margin-bottom: 0.8rem;
        font-size: $body1Size;
        ~ p {
            margin-bottom: 0;
            font-size: $body2Size;
            color: $fontColor-4;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    &-quantity {
        position: absolute;
        right: 0;
        bottom: 0;
        .box-quantity {
            background-color: $primaryColorDarken;
            color: $fontBackground;
            padding: 6px 10px;
            border-radius: 6px;
        }
    }
    .price {
        color: $fontColor-1;
        font-size: $body1Size;
        font-weight: $fontWeightBold;
    }
    .stock {
        position: absolute;
        left: -4px;
        top: 70px;
        padding: 0 8px;
        background-color: $successColorMain;
        color: white;
    }
    &.sold-out {
        opacity: 0.5;
        .stock {
            background-color: gray;
        }
    }
    &-desc {
        > :last-child {
            margin-bottom: 0;
        }
    }
    &-warn {
        position: absolute;
        right: 20px;
        bottom: 12px;
        color: $fontColor-4;
    }
}
