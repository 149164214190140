$_typography: '.typography';
$alignMap: center, inherit, justify, left, right;
// $variantStringMap: "h1", "h2", "h3", "h4", "h5", "h6", "subtitle1", "subtitle2", "body1", "body2";
$variantStringMap: 'subtitle1', 'subtitle2', 'body1', 'body2';
#{$_typography}-root {
    @at-root #{$_typography}-display-block {
        display: block;
    }
    @at-root #{$_typography}-display-inline-block {
        display: inline-block;
    }
    @each $variant in $variantStringMap {
        @at-root #{$_typography}-#{$variant} {
            @include mxVariants($variant);
        }
    }
    @at-root #{$_typography}-text-primary {
        color: $textPrimaryColor;
    }
    @at-root #{$_typography}-text-secondary {
        color: $textSecondaryColor;
    }
    @at-root #{$_typography}-text-white {
        color: $backgroundDefault;
    }
    @at-root #{$_typography}-text-error {
        color: $errorColorDarken;
    }
    @at-root #{$_typography}-text-gray {
        color: gray(500);
    }
    @at-root #{$_typography}-text-main {
        color: $primaryColorDarken;
    }
    @at-root #{&}-gutter-bottom {
        margin-bottom: 0.35em;
    }
    @at-root #{$_typography}-paragraph {
        margin-bottom: 16px;
    }
    @at-root #{$_typography}-no-mb {
        margin-bottom: 0;
    }
    @at-root #{$_typography}-no-wrap {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @at-root #{$_typography}-h1 {
        font-size: $h1FontSize;
    }
    @at-root #{$_typography}-h2 {
        font-size: $h2FontSize;
    }
    @at-root #{$_typography}-h3 {
        font-size: $h3FontSize;
    }
    @at-root #{$_typography}-h4 {
        font-size: $h4FontSize;
    }
    @at-root #{$_typography}-h5 {
        font-size: $h5FontSize;
    }
    @at-root #{$_typography}-h6 {
        font-size: $h6FontSize;
    }

    @each $align in $alignMap {
        @at-root #{$_typography}-text-align-#{$align} {
            text-align: $align;
        }
    }
}
