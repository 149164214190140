%apo-item {
    position: relative;
    text-align: center;
    padding: 1.6rem;
    cursor: pointer;
}
// 預約分類項目
.member-apo-catalog-items {
    background-color: $bg-light;
    .item-text {
        @extend %apo-item;
        border-right-width: 1px;
        border-right-color: $borderColor-2;
        border-style: solid;
    }
    .clicked {
        color: #fff;
        background-color: $primaryColorMain;
        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: calc(50% - 5px);
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #fff;
        }
    }
}
// 預約子項目
.member-apo-type-items {
    .item-text {
        @extend %apo-item;
    }
    .clicked {
        color: $primaryColorMain;
        background-color: $primaryColorLight;
    }
}

// 預約時間表單
.apo-time-form-list {
    @extend .ban-select;
    height: 550px;
    overflow-y: auto;
    .label-header {
        background: $primaryGradientMain;
        color: #fff;
    }
    // first column
    .label-time-first-column {
        width: 100px;
    }
    // second column
    .label-time-second-column {
        flex-grow: 1;
    }

    .label-text {
        padding: 2rem;
        font-size: 1.8rem;
    }

    .lable-catalog {
        background-color: $primaryColorLight;
        padding: 6px 16px;
        border-radius: 8px;
        .label-time-first-column {
            font-weight: $fontWeightMedium;
        }
    }

    .row-item {
        position: relative;
        cursor: pointer;
    }

    .row-divider + .row-divider {
        border-top: solid 1px $borderColor-2;
    }

    .clicked {
        background-color: darken($primaryColorLight, 10%);
    }
}
