.upload-feedback-box {
    width: 100%;
    position: relative;
    //padding-top: 2rem;
    //padding-bottom: 2rem;
    //border: 2px dashed lighten($primaryColorMain, 30%);
    //border-radius: 1rem;
    //background-color: lighten($primaryColorMain, 40%);
    .desc {
        color: $primaryColorDarken;
        font-size: 1.4rem;
        background-color: rgba(black, 0.1);
        padding: 0.4rem 0.8rem;
        border-radius: 2px;
    }
    .is-error {
        color: $errorColorMain;
        background-color: rgba($errorColorMain, .1);
    }
    .is-success {
        color: $successColorMain;
        background-color: rgba($successColorMain, .1);
    }
    &-wrap {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    &-content {
        width: 70%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -48%);
    }
    &-title {
        padding: .8rem;
        font-weight: $fontWeightMedium;
        margin-bottom: 2.4rem;
        border-radius: 4px;
        //&.is-error {
        //    background-color: transparent;
        //    border: 1px solid $errorColorMain;
        //}
        //&.is-success {
        //    background-color: transparent;
        //    border: 1px solid $successColorMain;
        //}
    }
    &-img {
        max-width: 8rem;
        display: block;
        margin: 0 auto 1rem;
    }
}
