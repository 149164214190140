$_stack: ".stack";
$flexWraps: "wrap-reverse", "wrap", "revert", "nowrap";

#{$_stack}-root {
    display: flex;
    @at-root #{$_stack}-direction-column {
        flex-direction: column;
        @for $i from 1 through length($gridSpacing) {
            &#{$_stack}-spacing-#{$i} {
                & > :not(style) + :not(style) {
                    margin: 0;
                    margin-top: nth($gridSpacing, $i);
                }
            }
        }
    }
    @at-root #{$_stack}-direction-column-reverse {
        flex-direction: column-reverse;
        @for $i from 1 through length($gridSpacing) {
            &#{$_stack}-spacing-#{$i} {
                & > :not(style) + :not(style) {
                    margin: 0;
                    margin-bottom: nth($gridSpacing, $i);
                }
            }
        }
    }
    @at-root #{$_stack}-direction-row-reverse {
        flex-direction: row-reverse;
        @for $i from 1 through length($gridSpacing) {
            &#{$_stack}-spacing-#{$i} {
                & > :not(style) + :not(style) {
                    margin: 0;
                    margin-right: nth($gridSpacing, $i);
                }
            }
        }
    }
    @at-root #{$_stack}-direction-row {
        flex-direction: row;
        @for $i from 1 through length($gridSpacing) {
            &#{$_stack}-spacing-#{$i} {
                & > :not(style) + :not(style) {
                    margin: 0;
                    margin-left: nth($gridSpacing, $i);
                }
            }
        }
    }
    @at-root #{$_stack}-x-stretch {
        align-items: stretch;
    }
    @at-root #{$_stack}-x-center {
        align-items: center;
    }
    @at-root #{$_stack}-x-start {
        align-items: start;
    }
    @at-root #{$_stack}-x-end {
        align-items: end;
    }
    @at-root #{$_stack}-x-average {
        flex-direction: row;
        & > * {
            flex-grow: 1;
        }
    }

    @at-root #{$_stack}-y-start {
        justify-content: start;
    }
    @at-root #{$_stack}-y-end {
        justify-content: end;
    }
    @at-root #{$_stack}-y-center {
        justify-content: center;
    }
    @at-root #{$_stack}-y-space-between {
        justify-content: space-between;
    }
    @at-root #{$_stack}-y-space-around {
        justify-content: space-around;
    }
    @at-root #{$_stack}-y-space-evenly {
        justify-content: space-evenly;
    }

    @each $fw in $flexWraps {
        @at-root #{$_stack}-flex-wrap-#{$fw} {
            flex-wrap: #{$fw};
        }
    }
}

// Allen todo
// //xs
// @media (min-width:0) {
// }
// //sm
// @media (min-width:600) {
// }
// //md
// @media (min-width:900) {
// }
// //lg
// @media (min-width:1200) {
// }
