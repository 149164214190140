.login {
    &-header {
        width: 100%;
        height: auto;
    }
    &-header-container {
        position: relative;
        height: 350px;
    }
    &-header-bg {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        height: 320px;
        width: 320px;
    }
    &-header-1 {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    &-header-2 {
        position: absolute;
        top: 8%;
        right: 0;
    }
    &-logo {
        position: absolute;
        z-index: 1;
        top: 36%;
        width: 300px;
        filter: drop-shadow(0px 2px 2px rgb(0 0 0 / 0.4));
    }
    &-form {
        position: relative;
        text-align: center;
        padding: 0 2rem 2rem;
        z-index: 20;
        > div:not(last-child) {
            margin-bottom: 1.6rem;
        }
        input {
            font-size: 2rem;
            height: 32px;
        }
        button {
            font-size: 2rem;
            margin-top: 20px;
            height: 52px;
            width: 200px;
            background-color: white;
        }
    }
    &-body {
        width: 100%;
        display: block;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
    }
    &-body-container {
        max-width: 450px;
        margin: 0 auto;
    }
    &-btn-confirm {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 202px;
        width: 100%;
        z-index: 10;
        > img {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }
    &-copyright {
        font-size: 1.6rem;
        text-align: right;
        color: #fff;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0 60px 30px 0;
    }
}

@media (max-width: 1080px) {
    .login {
        &-copyright {
            padding: 0 20px 20px 0;
        }
    }
}
