.elevation-0 {
    box-shadow: none;
}
.elevation-1 {
    box-shadow: map-get($shadows, 1);
}
.elevation-2 {
    box-shadow: map-get($shadows, 2);
}
.elevation-3 {
    box-shadow: map-get($shadows, 3);
}
.elevation-4 {
    box-shadow: map-get($shadows, 4);
}
.elevation-6 {
    box-shadow: map-get($shadows, 6);
}
.elevation-8 {
    box-shadow: map-get($shadows, 8);
}
.elevation-12 {
    box-shadow: map-get($shadows, 12);
}
.elevation-16 {
    box-shadow: map-get($shadows, 16);
}
.elevation-24 {
    box-shadow: map-get($shadows, 24);
}
