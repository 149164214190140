.membership-progress-bar {
  list-style: none;
  padding-left: 0;
  width: 100%;
  height: 1rem;
  border-radius: $buttonBorderRadius;
  position: relative;
  box-shadow: 0 0 2px 2px inset rgba(0, 0, 0, 0.1);

  &-box {
    padding-top: 5.2rem;
    position: relative;

    .date {
      color: $fontColor-3;
      position: absolute;
      bottom: 10px;

      span {
        display: block;
      }

      &-start {
        left: 0;
      }

      &-end {
        right: 0;
      }
    }
  }

  &-slice {
    height: 100%;
    background-color: $gray-300;
    position: absolute;

    &.has-pointer {
      cursor: pointer;
    }

    &.is-focus {
      transform: scaleY(1.6);
      transition: transform #{$durationStandard + 'ms'} $easeInOut;
      z-index: 10;
      box-shadow: map-get($shadows, 5);
    }

    &:nth-last-child(3),
    &:nth-last-child(1) {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  .range-handle {
    position: absolute;
    top: 4px;
    width: 44px;
    height: 44px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0 0 3px 3px rgba(#000, 0.1);
    border-radius: 50%;
  }

  .bar-today-arrow {
    top: 8px;
    width: 10px;
    height: 10px;
    position: absolute;
    transform: translateX(-50%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid black;
    margin-right: -6px;

    &:before {
      content: '\73fe\5728'; //現在
      width: 28px;
      font-size: 12px;
      font-weight: $fontWeightMedium;
      position: absolute;
      top: 12px;
      left: -13px;
    }
  }

  .unfinished {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    pointer-events: none;
  }
}

.membership-progress-bar-tooltip {
  box-shadow: map-get($shadows, 10);
  margin-top: 0.8rem;
  position: relative;
  border-radius: $shapeBorderRadius;

  &-wrap {
    color: $fontColor-2;
    background-color: white;
    padding: 1.2rem 5.6rem;
    border-radius: $shapeBorderRadius;
    position: relative;
    z-index: 10;
  }

  .btn-close {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    z-index: 20;
    background-color: white;
  }

  &-prev,
  &-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.icon-button-disabled {
      opacity: 0;
    }
  }

  &-prev {
    left: 0.4rem;
  }

  &-next {
    right: 0.4rem;
  }
}

.membership-progress-bar-controller {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  .icon-button {
    padding: 1.2rem;
    background-color: rgba(0, 0, 0, 0.04);

    .svg-icon-root {
      fill: $fontColor-1;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.icon-button-disabled {
      .svg-icon-root {
        fill: gray(300);
      }
    }
  }

  &.next {
    right: 0;
  }

  &.prev {
    left: 0;
  }

  &-box {
    position: relative;
    min-height: 4.8rem;

    .membership-progress-bar-box {
      width: calc(100% - 136px);
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.membership-progress-bar-non-suspend-date {
  width: calc(100% - 136px);
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 12px;
}
