/*
    常用 tabs (非頁首)排版方式
 */
.tabs-content-layout {
    .page-header {
        border-bottom: 1px solid $borderColor-2;
        margin-bottom: 0;

        .title {
            padding: 2.4rem 2.4rem 1.6rem;
            margin-bottom: 0;
            margin-top: 0;
        }

        .btn-group {
            margin-right: 1.6rem;
        }

        .tabs-root {
            margin-left: 2.4rem;
            padding: 0;
            margin-bottom: -2px;

            .button-base {
                padding: 2.4rem 2rem 2rem;
            }
        }
    }
}

.tabs-label {
    position: relative;
    margin-bottom: 0;
    &-count {
        padding: 2px 10px;
        margin-left: 10px;
        border-radius: 50%;
        background-color: $bg-light-active;
        color: $fontColor-1;
    }
    .tab-selected & {
        &-count {
            background-color: $primaryColorMain;
            color: white;
        }
    }
}
