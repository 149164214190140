.process-circle {
    .svg circle {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 1s linear;
        stroke: #666;
        stroke-width: 1em;
    }
    .svg .bar {
        stroke-dashoffset: 565.48;
        stroke: #ff9f1e;
    }
    .cont {
        display: block;
        height: 200px;
        width: 200px;
        margin: 2em auto;
        border-radius: 100%;
        position: relative;
    }
    .cont:after {
        position: absolute;
        display: block;
        height: 160px;
        width: 160px;
        left: 50%;
        top: 50%;
        content: attr(data-pct) '%';
        margin-top: -80px;
        margin-left: -80px;
        border-radius: 100%;
        line-height: 160px;
        font-size: 2em;
        text-align: center;
    }
}
