.scroll-arrow-container {
    position: relative;
    .scroll-arrow-wrap {
        scroll-behavior: smooth;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    .scroller-right-button,
    .scroller-left-button {
        z-index: $speedDialZIndex;
        position: absolute;
        top: 10px;
    }
    .scroller-right-button {
        right: 20px;
    }
    .scroller-left-button {
        left: 20px;
    }
    .arrow-button {
        background: rgba(#fff, 0.7);
        box-shadow: 0 0 5px rgba(#000, .3);
    }
    .hidden {
        display: none;
    }
}



