.notification-menus {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    background-color: #5b2a7a;
    overflow-y: auto;

    .notification-menus-container {
        padding: 0;
        padding: 0;
        > .list-item-button {
            &:not(:first-child) {
                border-top: 1px solid $borderColor-2;
            }
        }

        .list-item-button {
            &.is-current {
                background-color: darken($primaryColorMain, 10);
            }
            &:hover {
                background-color: darken($primaryColorMain, 20);
            }
        }

        .list-item-text {
            color: white;
        }

        .svg-icon-root {
            color: white;
        }

        .logo {
            padding: 3rem 2rem;
            text-align: center;
            filter: brightness(100);
            img {
                height: 40px;
            }
        }
    }

    .count-dot {
        background-color: $errorColorDarken;
        color: $backgroundDefault;
    }
}

// 通知畫面用
.notification-content {
    margin: $notifyMenuMarginY $notifyMenuMarginX;

    @media (max-width: $breakpoint-md) {
        margin-left: 250px + $notifyMenuMarginX;
    }
    
    .notification-content-bg {
        background: #f0f0f0;
    }
    .notification-dot {
        position: relative;
        padding: 20px;
        &::before {
            --redius: 10px;
            content: '';
            position: absolute;
            width: var(--redius);
            height: var(--redius);
            border-radius: var(--redius);
            background-color: $errorColorDarken;
            top: 10px;
            left: calc(50% - var(--redius));
        }
        &.read::before {
            content: unset;
        }
    }
}
