$typographyVariantsMap: (
    'h1': (
        $fontWeightMedium,
        $h1FontSize,
        1.2,
        -0.5,
    ),
    'h2': (
        $fontWeightMedium,
        $h2FontSize,
        1.167,
        0,
    ),
    'h3': (
        $fontWeightMedium,
        $h3FontSize,
        1.235,
        0.25,
    ),
    'h4': (
        $fontWeightMedium,
        $h4FontSize,
        1.334,
        0,
    ),
    'h5': (
        $fontWeightMedium,
        $h5FontSize,
        1.6,
        0.15,
    ),
    'h6': (
        $fontWeightMedium,
        $h6FontSize,
        1.6,
        0.15,
    ),
    'subtitle1': (
        $fontWeightRegular,
        $subtitle1Size,
        1.75,
        0.15,
    ),
    'subtitle2': (
        $fontWeightMedium,
        $subtitle2Size,
        1.57,
        0.1,
    ),
    'body1': (
        $fontWeightRegular,
        $body1Size,
        1.5,
        0.15,
    ),
    'body2': (
        $fontWeightRegular,
        $body2Size,
        1.43,
        0.15,
    ),
    'button': (
        $fontWeightMedium,
        $buttonFontSize,
        1.75,
        0.4,
        true,
    ),
    'caption': (
        $fontWeightRegular,
        $captionSize,
        1.66,
        0.4,
    ),
    // "overline": ($fontWeightRegular, 1.2, 2.66, 1, true),,,,,,,,
);

// shadow
$shadow-1: 0, 2, 1, -1, 0, 1, 1, 0, 0, 1, 3, 0 !default;
$shadow-2: 0, 3, 1, -2, 0, 2, 2, 0, 0, 1, 5, 0 !default;
$shadow-3: 0, 3, 3, -2, 0, 3, 4, 0, 0, 1, 8, 0 !default;
$shadow-4: 0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0 !default;
$shadow-5: 0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0 !default;
$shadow-6: 0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0 !default;
$shadow-7: 0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1 !default;
$shadow-8: 0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2 !default;
$shadow-9: 0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2 !default;
$shadow-10: 0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3 !default;
$shadow-11: 0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3 !default;
$shadow-12: 0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4 !default;
$shadow-13: 0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4 !default;
$shadow-14: 0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4 !default;
$shadow-15: 0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5 !default;
$shadow-16: 0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5 !default;
$shadow-17: 0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5 !default;
$shadow-18: 0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6 !default;
$shadow-19: 0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6 !default;
$shadow-20: 0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7 !default;
$shadow-21: 0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7 !default;
$shadow-22: 0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7 !default;
$shadow-23: 0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8 !default;
$shadow-24: 0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8 !default;
$shadows: () !default;
$shadows: map-merge(
    (
        1: fnCreateShadow($shadow-1...),
        2: fnCreateShadow($shadow-2...),
        3: fnCreateShadow($shadow-3...),
        4: fnCreateShadow($shadow-4...),
        5: fnCreateShadow($shadow-5...),
        6: fnCreateShadow($shadow-6...),
        7: fnCreateShadow($shadow-7...),
        8: fnCreateShadow($shadow-8...),
        9: fnCreateShadow($shadow-9...),
        10: fnCreateShadow($shadow-10...),
        11: fnCreateShadow($shadow-11...),
        12: fnCreateShadow($shadow-12...),
        13: fnCreateShadow($shadow-13...),
        14: fnCreateShadow($shadow-14...),
        15: fnCreateShadow($shadow-15...),
        16: fnCreateShadow($shadow-16...),
        17: fnCreateShadow($shadow-17...),
        18: fnCreateShadow($shadow-18...),
        19: fnCreateShadow($shadow-19...),
        20: fnCreateShadow($shadow-20...),
        21: fnCreateShadow($shadow-21...),
        22: fnCreateShadow($shadow-22...),
        23: fnCreateShadow($shadow-23...),
        24: fnCreateShadow($shadow-24...),
    ),
    $shadows
);

// gray
$gray-50: #f0f0f0 !default;

$gray-100: #e3e3e3  !default;
$gray-200: #cccccc !default;
$gray-300: #bbbbbb !default;
$gray-400: #a3a3a3 !default;
$gray-500: #8c8c8c !default;
$gray-600: #5d5d5d !default;
$gray-700: #464646 !default;
$gray-800: #2e2e2e !default;
$gray-900: #1c1c1c !default;

$blue-gray-50: #E3EBEF !default;
$blue-gray-100: #cfd8dc !default;
$blue-gray-200: #b0bec5 !default;

$colorMainMap: (
    'primary': $primaryColorMain,
    'secondary': $secondaryColorMain,
    'warning': $warningColorMain,
    'error': $errorColorMain,
    'success': $successColorMain,
    'info': $infoColorMain,
    'gray': $gray-600,
    'blue-gray': $blue-gray-50,
);
$colorLightMap: (
    'primary': $primaryColorLight,
    'secondary': $secondaryColorLight,
    'warning': $warningColorLight,
    'error': $errorColorLight,
    'success': $successColorLight,
    'info': $infoColorLight,
    'gray': $gray-400,
    'blue-gray': $blue-gray-50,
);
$colorDarkenMap: (
    'primary': $primaryColorDarken,
    'secondary': $secondaryColorDarken,
    'warning': $warningColorDarken,
    'error': $errorColorDarken,
    'success': $successColorDarken,
    'info': $infoColorDarken,
    'gray': $gray-800,
);



$grays: () !default;
$grays: map-merge(
    (
        50: $gray-50,
        100: $gray-100,
        200: $gray-200,
        300: $gray-300,
        400: $gray-400,
        500: $gray-500,
        600: $gray-600,
        700: $gray-700,
        800: $gray-800,
        900: $gray-900,
    ),
    $grays
);

$directionMap: (
    'col': 'bottom',
    'row': 'right',
);

$breakpoint-lg: '1600px';
$breakpoint-md: '1180px';
