.sign-box {
    width: 100%;
    position: relative;
    &-wrap {
        position: relative;
        cursor: pointer;
        border: 1px solid $borderColor;
        background-color: $bg-light;
        border-radius: $shapeBorderRadius;
        &::after {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 30%;
        }
    }

    &-title {
        --size: 34px;
        color: white;
        font-size: 1.5rem;
        line-height: var(--size);
        font-weight: $fontWeightMedium;
        padding: 0 1rem 0 1.5rem;
        background: darken($errorColorMain, 10%);
        position: relative;
        z-index: 1000;
        &::after {
            content: '';
            position: absolute;
            left: 100%;
            top: 0;
            border-style: solid;
            border-color: transparent transparent transparent darken($errorColorMain, 10%);
            border-width: calc(var(--size) / 2) 0 calc(var(--size) / 2) 12px;
        }
        &-wrap {
            position: absolute;
            top: 1rem;
        }
    }
    &-img {
        width: 100%;
        height: 100%;
        vertical-align: middle;
        object-fit: contain;
        &-wrap {
            position: absolute;
            left: 9rem;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            overflow: hidden;
        }
    }
    &-error {
        .sign-box-wrap {
            border-color: $errorColorMain;
        }
    }
}
