/* Members */
.members {
    &-tabs {
        display: flex;
        margin: 0 0 20px;
        position: relative;
    }
    &-content {
        margin: 24px 0 0 0;
    }
}

.member-box-name-label {
    font-weight: $fontWeightMedium;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.member-box-nickname-label {
    font-size: $body2Size;
    margin-bottom: 0;
    color: $infoColorMain;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.member-box-other {
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.membership-state-wrap {
    .membership-state {
        margin-top: 3px;
        margin-right: 8px;
    }
    > p {
        margin-bottom: 0;
    }
}
.membership-state {
    width: 1.6rem;
    height: 1.6rem;
    border: 2px solid white;
    border-radius: 50%;
    background-color: $primaryColorMain;
}
