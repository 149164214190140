.badge-root {
    position: relative;
    display: inline-flex;
    // For correct alignment with the text.
    vertical-align: middle;
    flex-shrink: 0;
    .badge{
        
    }
}
