.cycle-order-card {
    position: relative;
    border: 1px solid $borderColor-2;
    border-radius: $shapeBorderRadius;
    &-header {
        padding: 2rem 1.6rem;
        border-bottom: 1px solid $borderColor-2;
        &-actions {
            position: absolute;
            right: 1rem;
            top: 1rem;
        }
        .simple-content-item {
            &-label {
                font-size: 1.6rem;
            }
        }
    }
    &-footer {
        background-color: #f7eefe;
        &-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1.6rem;
            border-bottom: 1px solid $borderColor-2;
        }
        &-actions {
            .icon-button {
                padding-bottom: 0.4rem;
                &:hover,
                &.current {
                    .svg-icon-root {
                        color: $secondaryColorMain;
                    }
                    .typography-root {
                        color: $secondaryColorMain;
                    }
                    &::before {
                        content: none;
                    }
                }
            }
        }
    }
    .total {
        span:first-child {
            margin-right: 2rem;
        }
        .h5 {
            color: $primaryColorMain;
        }
    }
    &.quit {
        opacity: 0.7;
        background-color: $bg-light;
        .cycle-order-card-footer {
            background-color: transparent;
        }
        .total {
            .h5 {
                color: $fontColor-1;
            }
        }
    }
    .decorate-delete-chip {
        position: absolute;
        top: 7.6rem;
        right: 0;
        border-radius: 3rem 0 0 3rem;
        padding: 0 1rem;
    }
}

.minor-title {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    .svg-icon-root {
        color: $warningColorMain;
        margin-right: 1rem;
    }
    .typography-root {
        color: $warningColorMain;
        margin-bottom: 0;
    }
}

.cycle-order-card.minor {
    .cycle-order-card {
        &-header {
            background-color: $bg-light;
        }
    }
}
