
// text color
.text {
    color: gray(900);
    @each $name, $color in $colorMainMap {
        &-#{$name} {
            color: $color !important;
        }
    }
    @each $name, $color in $colorLightMap {
        &-#{$name}-light {
            color: $color !important;
        }
    }
    @each $name, $color in $colorDarkenMap {
        &-#{$name}-dark {
            color: $color !important;
        }
    }
    @each $name, $color in $grays {
        &-gray-#{$name} {
            color: $color !important;
        }
    }
}

.font-color {
    @each $color in $fontColors {
        $i: index($fontColors, $color);
        &-#{$i} {
            color: $color !important;
        }
    }
}

// bg color
.bg {
    @each $name, $color in $colorMainMap {
        &-#{$name} {
            background-color: $color !important;
        }
    }
    @each $name, $color in $colorLightMap {
        &-#{$name}-light {
            background-color: $color !important;
        }
    }
    @each $name, $color in $colorDarkenMap {
        &-#{$name}-dark {
            background-color: $color !important;
        }
    }
    @each $name, $color in $grays {
        &-gray-#{$name} {
            background-color: $color !important;
        }
    }
    &-blue-50 {
        background-color: #e1f5fe !important;
    }
    &-blue-gray-50 {
        background-color: #eceff1 !important;
    }
}

.bg-white {
    background-color: white !important;
}

.bg-black {
    background-color: black !important;
}

.bg-dark-1 {
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.svg-color-star {
    fill: #FFA748 !important;
}

.bg-light {
    background-color: $bg-light !important;
}

.bg-light-active {
    background-color: $bg-light-active !important;
}
