.radio-button-icon {
    position: relative;
    display: flex;
}
.radio-button-icon-unchecked {
    transform: scale(1);
}
.radio-button-icon-icondot {
    left: 0;
    position: absolute;
    transform: scale(0);
    transition: transform 150ms cubic-bezier(0.4, 0, 1, 1) 0ms;
    &#{&}-checked {
        transform: scale(1);
        transition: transform 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }
}
