.cycle-order-history {
    &-card {
        background-color: $bg-light;
        border: 1px solid $borderColor-2;
        border-radius: $shapeBorderRadius;
        padding: 1.2rem 2rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &-img {
            cursor: pointer;
            display: block;
            max-width: 6rem;
            border-radius: 50%;
            background-color: white;
            box-shadow: map-get($shadows, 4);
            padding: 1.2rem;
            margin-right: 2.4rem;
            > img {
                width: 100%;
                height: auto;
                vertical-align: middle;
            }
        }
        &-content {
            .title {
                font-weight: $fontWeightMedium;
            }
            .date-range {
                margin-right: 2rem;
            }
            > :last-child {
                margin-bottom: 0;
            }
        }
    }

    &-info {
        display: flex;
        > p {
            margin: 0;
            flex: 1 1 auto;
            padding: 0.8rem 1rem;
            .label {
                color: $fontColor-3;
                margin-right: 2rem;
            }
        }
        .coach {
            margin-left: auto;
            text-align: right;
        }
    }
}
