img,
svg {
    vertical-align: middle;
}

.img {
    &-fluid {
        max-width: 100%;
        height: auto;
    }

    &-thumbnail {
        padding: 0.25rem;
        background-color: #fff;
        border: 1px solid #dee2e6;
        border-radius: 0.25rem;
        max-width: 100%;
        height: auto;
    }
}
