.step {
  &--horizontal {
    padding-left: 8px;
    padding-right: 8px;
  }

  &--alternative-label {
    flex: 1 1 0%;
    position: relative;
  }
}
