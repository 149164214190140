// 健康申告書
.contract-health-declaration {
    .form-control-root {
        > .form-group-root {
            flex-wrap: nowrap;
        }
    }
}

// 會員健康申告書>問題
.questions {
    position: relative;
    z-index: 1;
    .form-control-root {
        display: flex;
        justify-content: space-between;
        .formlabel {
            & > span {
                line-height: 4.2rem;
                &:not([class*='asterisk']) {
                    color: #181c32;
                    font-size: 1.6rem;
                }
            }
        }
    }
    .questions {
        list-style: decimal-leading-zero;
        padding-left: 4rem;
        > .questions-item {
            &:nth-child(even) {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: calc(100% + 4rem);
                    height: 100%;
                    background-color: $bg-light;
                    border-radius: $shapeBorderRadius;
                    z-index: -1;
                }
            }
        }
    }
    &-item {
        line-height: 4.2rem;
    }

    &.main {
        > .questions-item + .questions-item {
            padding-top: 1rem;
        }

        > .questions-item:last-child {
            padding-bottom: 2rem;
        }
    }

    .row {
        display: flex;
        > div {
            margin-right: 2rem;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
