$_paginationItemEllipsis: ".pagination-item-ellipsis";
#{$_paginationItemEllipsis}-root {
    @include mxVariants("body2");
    text-align: center;
    box-sizing: border-box;
    min-width: 32px;
    padding: 0 6px;
    margin: 0 3px;
    color: $textPrimaryColor;
    height: auto;
    &#{$_paginationItemEllipsis}-disabled {
        opacity: $actionDisabledOpacity;
    }
    &#{$_paginationItemEllipsis}-size-small {
        min-width: 26px;
        border-radius: 26px / 2px;
        margin: 0 1px;
        padding: 0 4px;
    }
    &#{$_paginationItemEllipsis}-size-large {
        min-width: 40px;
        border-radius: 40px / 2px;
        padding: 0 10px;
        font-size: 1.5rem;
    }
}

$_paginationItemPage: ".pagination-item-page";
$_paginationItemPageColorMap: (
    "primary": $primaryColorMain,
    "secondary": $secondaryColorMain,
);
#{$_paginationItemPage}-root {
    @include mxVariants("body2");
    border-radius: calc(32 / 2) + px;
    text-align: center;
    box-sizing: border-box;
    min-width: 32px;
    height: 32px;
    padding: 0 6px;
    margin: 0 3px;
    border: 1px solid;
    background-color: #fff;
    color: $textPrimaryColor;
    &#{$_paginationItemPage}-focus-visible {
        background-color: $actionFocusColor;
    }
    &#{$_paginationItemPage}-disabled {
        opacity: $actionDisabledOpacity;
    }
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
        background-color: $actionHoverColor;
        @media (hover: none) {
            background-color: transparent;
        }
    }
    &#{$_paginationItemPage}-selected {
        background-color: $activeSelectedColor;
        &:hover {
            background-color: rgba($activeSelectedColor, ($actionHoverOpacity + $actionDisabledOpacity));
            // Reset on touch devices, it doesn't add specificity
            @media (hover: none) {
                background-color: $activeSelectedColor;
            }
        }
        &#{$_paginationItemPage}-focus-visible {
            opacity: 1;
            color: $actionDisabledColor;
            background-color: $activeSelectedColor;
        }
    }
    &#{$_paginationItemPage}-size-small {
        min-width: 26px;
        height: 26px;
        border-radius: calc(26 / 2) + px;
        margin: 0 1px;
        padding: 0 4px;
    }
    &#{$_paginationItemPage}-size-large {
        min-width: 40px;
        height: 40px;
        border-radius: calc(40 / 2) + px;
        padding: 0 10px;
        font-size: 1.5rem;
    }
    &#{$_paginationItemPage}-shape-rounded {
        border-radius: $shapeBorderRadius;
    }
}
// Allen to do
#{$_paginationItemPage}-variant-text {
    &#{$_paginationItemPage}-selected {
        @each $key, $value in $_paginationItemPageColorMap {
            &#{$_paginationItemPage}-color-#{$key} {
                color: #fff;
                background-color: $value;
                &:hover {
                    background-color: darken($value, 10%);
                    // Reset on touch devices, it doesn't add specificity
                    @media (hover: none) {
                        background-color: $value;
                    }
                }
                &#{$_paginationItemPage}-focus-visible {
                    background-color: darken($value, 10%);
                }
            }
        }
        &#{$_paginationItemPage}-disabled {
            color: $actionDisabledColor;
        }
    }
}

#{$_paginationItemPage}-variant-outlined {
    border: 1px solid #b4b4b4;
    &#{$_paginationItemPage}-selected {
        @each $key, $value in $_paginationItemPageColorMap {
            &#{$_paginationItemPage}-color-#{$key} {
                color: $value;
                border: 1px solid rgba($value, 0.5);
                background-color: rgba($value, $actionActivatedOpacity);
                &:hover {
                    background-color: rgba($value, ($actionActivatedOpacity + $actionFocusOpacity));
                    // Reset on touch devices, it doesn't add specificity
                    @media (hover: none) {
                        background-color: transparent;
                    }
                }
                &#{$_paginationItemPage}-focus-visible {
                    background-color: rgba($value, ($actionActivatedOpacity + $actionFocusOpacity));
                }
            }
        }
        #{$_paginationItemPage}-disabled {
            border-color: $actionDisabledBackground;
            color: $actionDisabledColor;
        }
    }
}

$_paginationItemIcon: ".pagination-item-icon";
#{$_paginationItemIcon} {
    font-size: 2rem;
    margin: 0 -8px;
    &#{&}-size-small {
        font-size: 1.8rem;
    }
    &#{&}-size-large {
        font-size: 2.2rem;
    }
}
