@import "../../mixins";
@import "../../functions";
@import "../../variables";

// 入會申請書
// 扣款資訊
.info-box {
    padding: 1.2rem 2rem;
    background-color: $bg-light;
    border-radius: 0.6rem;
    > :last-child {
        margin-bottom: 0;
    }
}

// 扣款授權付款項目 table
.shopping-list {
    margin-bottom: 4rem;
    .table {
        &-row {
            > :first-child {
                padding-left: 2.8rem;
            }
            > :last-child {
                padding-right: 2.8rem;
            }
        }
    }
}

// 歷史紀錄
.document-history-list {
    padding: 1.6rem 2.4rem;
    .note {
        display: -webkit-box;
        max-width: 20rem;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
    }
}

// 移出申請書>移籍資訊
.transfer-card {
    background-color: white;
    border-radius: $shapeBorderRadius;
    box-shadow: map-get($shadows, 3);
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    position: relative;
    gap: 5rem;
    &-item {
        padding: 2.4rem;
        flex: 1 1 100%;
        .read-text-field-text {
            font-size: 2rem;
            font-weight: $fontWeightMedium;
        }
        > div {
            + div {
                margin-top: 1.6rem;
            }
        }
    }
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("../../../scripts/icoach/assets/img/Icon_awesome-arrow-right.svg");
        width: 3rem;
        height: 3rem;
    }
}

// layout  表單
.field-group {
    padding-right: 0;
    padding-left: 0;
    position: relative;
    &:not(:first-child) {
        padding-top: 3rem;
    }
    &:not(:last-child) {
        padding-bottom: 3rem;
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            @include mxFakeDashed();
            width: calc(100% + 32px);
        }
    }
    .field-label {
        label {
            display: inline-block;
            line-height: 2.8rem;
        }
        span {
            line-height: 2.8rem;
        }
    }
}

.field-label {
    label {
        display: inline-block;
        line-height: 4rem;
    }
    span {
        line-height: 4rem;
    }
}

.field-content {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

// 通用樣式
.btn-minWidth {
    min-width: 11rem;
}

.workspace-box {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    z-index: $mobileStepperZIndex;
    text-align: right;
    svg.svg-icon-root,
    svg.MuiSvgIcon-root {
        font-size: 2.4rem;
        color: white;
    }
    .MuiSpeedDial-actions {
        svg.svg-icon-root,
        svg.MuiSvgIcon-root {
            color: $primaryColorMain;
        }
    }
    .button-variant-outlined {
        &.button-color-secondary {
            background-color: rgba(white, 80%);
            min-width: 12rem;
            text-align: center;
        }
    }
}

.search-bar {
    .inputbase-input.inputbase-input-disabled,
    .formlabel.formlabel-disabled {
        -webkit-text-fill-color: $fontColor-1;
    }
}

.doc-searchbar-layout-button{
    align-self: flex-start;
}

.lists {
    position: relative;
    &.ol {
        padding-left: 3.6rem;
        list-style: decimal;
    }
    &.ul {
        padding-left: 3.6rem;
        list-style: disc;
    }
    &.striped {
        z-index: 1;
        > .lists-item {
            &:nth-child(even) {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: calc(100% + 3.6rem);
                    height: 100%;
                    background-color: $gray-100;
                    border-radius: $shapeBorderRadius;
                    z-index: -1;
                }
            }
        }
    }
    // checkbox or radio
    &.controler {
        padding-left: 1.8rem;
        > .lists-item {
            padding: 0.4rem 0;
            &:nth-child(odd) {
                position: relative;
                &::after {
                    width: calc(100% + 1.8rem);
                }
            }
        }
    }
    & > &-item {
        padding: 1rem 1rem 1rem 0.6rem;
    }
}

// 須知 & 注意事項
.survey {
    > .all {
        display: flex;
        position: relative;
        padding-bottom: 1.2rem;
        > * {
            padding: 0 1.5rem;
        }
        .typography-root {
            font-weight: $fontWeightMedium;
        }
        &::before {
            content: "";
            display: block;
            width: 16rem;
            height: 1px;
        }
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            @include mxFakeDashed();
            width: calc(100% - 16rem);
        }
    }
    .sign-box {
        max-width: 40rem;
        margin: 2rem auto;
    }
}






