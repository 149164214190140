.cart {
    &-header {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 2.4rem;
        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            @include mxFakeDashed();
        }
    }
    &-title {
        font-size: $h6FontSize;
        margin: 0;
    }
    &-actions {
        position: relative;
        padding: 2rem 2.4rem;
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            @include mxFakeDashed();
        }
    }
    &-content {
        padding: 2.4rem;
    }
    &-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: .2rem;
        .list-item-text-primary {
            order: 1;
        }
        .list-item-text-secondary {
            font-size: $body1Size;
            &::after {
                content: ":";
            }
        }
        &.total {
            font-weight: $fontWeightMedium;
        }
    }
}
