.step-icon {
  user-select: none;
  width: 1em;
  height: 1em;
  display: block;
  fill: currentColor;
  color: gray(200);
  flex-shrink: 0;
  transition: getTransition(fill);

  &--completed {
    color: $primaryColorMain;
  }

  &--active {
    color: $primaryColorMain;
    .step-icon__text {
      fill: white;
    }
  }

  &--error {
    color: $errorColorMain;
  }

  &__completed {
    font-size: 24px;
    line-height: 1;
  }

  &__icon {
    font-size: 24px;
    line-height: 1;
  }
}

.step-icon__text {
  fill: white;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}