.promotions {
    position: relative;
    &-control {
        margin-bottom: 2rem;
        .button-base {
            min-height: unset;
            border-radius: 3rem;
            background-color: $bg-light-active;
            border: 1px solid rgba(0, 0, 0, 0);
            transition: 0.4s;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.1);
                opacity: 0;
                transition: 0.4s;
            }
            &.tab-selected {
                color: white;
                background-color: $primaryColorMain;
            }
            > font {
                z-index: 1;
            }
            &:not(:last-child) {
                margin-right: 2rem;
            }
        }
        .tabs-indicator {
            display: none !important;
        }
    }
    &-panel {
        background-color: $bg-light;
        padding: 2rem;
        border-radius: 0.6rem;
        .label {
            font-size: 1.6rem;
            color: $gray-800;
            text-align: right;
            line-height: 4rem;
            flex: 0 0 9rem;
        }

        .field {
            display: flex;
            + .desc {
                font-size: 1.3rem;
                margin-top: 1rem;
                margin-bottom: 0;
            }
            .inputbase-root {
                width: 25rem;
            }
            .button-base {
                margin-left: 1rem;
            }
        }
    }
}

.multiple-options {
    width: 100%;
    &-item {
        padding: 0.6rem 0 0.6rem 2rem;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &::before {
            --size: 0.5rem;
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            height: var(--size);
            width: var(--size);
            background-color: $gray-600;
            border-radius: 50%;
            transform: translateY(-50%);
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            @include mxFakeDashed();
        }
        > span {
            margin-top: 1rem;
            margin-bottom: 1rem;
            line-height: $lineHeight;
        }
    }
}
