$_button: '.button';
#{$_button}-root {
    font-weight: $fontWeightMedium;
    min-width: 64px;
    padding: 0.8rem 1.6rem;
    border-radius: $buttonBorderRadius;
    transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    @at-root #{$_button}-size-small {
        font-size: 1.4rem;
    }
    @at-root #{$_button}-size-medium {
        font-size: 1.6rem;
    }
    @at-root #{$_button}-size-large {
        font-size: 2.0rem;
        padding: 1.2rem 2.5rem;
    }
    @at-root #{$_button}-square {
        border-radius: 0;
    }
    @at-root #{$_button}-full-width {
        width: 100%;
    }
    &:hover:not(.button-disabled) {
        text-decoration: none;
        background-color: rgba(#601986, $actionHoverOpacity);
        // Reset on touch devices, it doesn't add specificity
        @media (hover: none) {
            background-color: transparent;
        }
        &#{$_button}-variant-text {
            @each $key, $value in $colorMainMap {
                &#{$_button}-color-#{$key} {
                    color: $value;
                }
            }
        }
        &#{$_button}-variant-outlined {
            border: 1px solid;
            box-shadow: map-get($shadows, 1);
            @each $key, $value in $colorMainMap {
                &#{$_button}-color-#{$key} {
                    background-color: rgba($value, 0.1);
                    border-color: $value;
                }
            }
        }
        &#{$_button}-variant-contained {
            box-shadow: map-get($shadows, 6);
            @each $key, $value in $colorMainMap {
                &#{$_button}-color-#{$key} {
                    //color: $adjusted-value;
                    background-color: darken($value, 8);

                }
            }
        }
    }
    @at-root #{$_button}-variant-text {
        @each $key, $value in $colorMainMap {
            &#{$_button}-color-#{$key} {
                color: $value;
            }
        }
    }
    @at-root #{$_button}-variant-outlined {
        letter-spacing: 0.1rem;
        border: 1px solid;
        @each $key, $value in $colorMainMap {
            &#{$_button}-color-#{$key} {
                color: $value;
                border-color: $value;
            }
        }
    }
    @at-root #{$_button}-variant-contained {
        letter-spacing: 0.1rem;
        box-shadow: map-get($shadows, 2);
        color: gray(50);
        @each $key, $value in $colorMainMap {
            $adjusted-value: if($key == 'blue-gray', $fontColor-2, white);
            &#{$_button}-color-#{$key} {
                color: $adjusted-value;
                background-color: $value;
            }
            .button-start-icon {
                > svg {
                    color: white;
                }
            }
            .button-end-icon {
                > svg {
                    color: white;
                }
            }
        }
    }

    @at-root #{$_button}-disabled {
        cursor: not-allowed;
        color: rgba(0, 0, 0, 0.26);
        &.button-variant-outlined {
            border-color: rgba(0, 0, 0, 0.26);
        }
        &.button-variant-contained {
            color: $actionDisabledColor;
            box-shadow: none;
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    @at-root #{$_button}-start-icon {
        display: inherit;
        margin-right: 8px;
        margin-left: -4px;
        &#{$_button}-size-small {
            margin-left: -2px;
        }
    }

    @at-root #{$_button}-end-icon {
        display: inherit;
        margin-right: -4px;
        margin-left: 8px;
        &#{$_button}-size-small {
            margin-right: -2px;
        }
    }
}
