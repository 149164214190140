$_avatar: ".avatar";
#{$_avatar}-root {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    font-size: 2rem;
    line-height: 1px;
    overflow: hidden;
    user-select: none;
    border-radius: 50%;
    font-family: $fontFamily;
    @at-root #{$_avatar}-color-default {
        color: $backgroundPaper;
        background: #bdbdbd;
    }

    @at-root #{$_avatar}-rounded {
        border-radius: $shapeBorderRadius;
    }

    @at-root #{$_avatar}-square {
        border-radius: 0;
    }

    @at-root #{$_avatar}-img {
        width: 100%;
        height: 100%;
        text-align: center;
        // Handle non-square image. The property isn't supported by IE11.
        object-fit: cover;
        // Hide alt text.
        color: transparent;
        // Hide the image broken icon, only works on Chrome.
        text-indent: 10000px;
    }

    @at-root #{$_avatar}-fallback {
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        display: inline-block;
        fill: currentColor;
        width: 75%;
        height: 75%;
    }
}
