$_memberSummaryDialog: ".member-summary-dialog";

#{$_memberSummaryDialog}-name {
    font-size: 2rem;
    padding: 0 6px 0 0;
}

#{$_memberSummaryDialog}-nickname {
    font-size: 1.6rem;
    color: gray(500);
}

#{$_memberSummaryDialog}-workout-number {
    color: $primaryColorMain;
    display: inline-block;
    width: 65px;
}

#{$_memberSummaryDialog}-text-panel {
    line-height: $lineHeight;
    padding: 2rem;
    background: $bg-light;
    border-radius: $shapeBorderRadius;
}

#{$_memberSummaryDialog}-text-title-panel {
    color: $primaryColorMain;
    padding: 0 0 1.2rem;
}
#{$_memberSummaryDialog}-text-context-panel {
    font-size: $body1Size;
    margin: unset;
}

#{$_memberSummaryDialog}-checked-panel + #{$_memberSummaryDialog}-checked-panel {
    margin-top: 16px;
}

#{$_memberSummaryDialog} {
    .business-card-box {
        .member-box-name-label {
            font-size: $h6FontSize;
        }
    }
    &-business {
        flex: 0 0 20rem;
    }
    &-workout {
        flex: 1 1 auto;
    }
    .counter-box {
        padding: 1.2rem;
        &-title {
            font-size: 1.48rem;
        }
    }
}
