.icon-button {
    text-align: center;
    flex: 0 0 auto;
    font-size: 24px;
    padding: 1rem;
    border-radius: 50%;
    // overflow: hidden;
    color: gray(50);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
        background-color: rgba(rgba(0, 0, 0, 0.54), 0.04);
        @media (hover: none) {
            background-color: transparent;
        }
    }

    &#{&}-edge-start {
        margin-left: -12px;
        &.icon-button-size-small {
            margin-left: -3px;
        }
    }
    _iconButton.scss &#{&}-edge-end {
        margin-right: -12px;
        &.icon-button-size-small {
            margin-right: -3px;
        }
    }

    &#{&}-size-small {
        padding: 12px;
        font-size: 18px;
    }

    &#{&}-size-large {
        padding: 12px;
        font-size: 28px;
    }

    &#{&}-color-inherit {
        color: inherit;
    }

    &#{&}-color-primary {
        color: #601986;
        &:hover {
            background-color: rgba(#601986, 0.04);
        }
    }

    &#{&}-color-secondary {
        color: #b268ba;
        &:hover {
            background-color: rgba(#b268ba, 0.04);
        }
    }

    &#{&}-color-success {
        color: #73c783;
        &:hover {
            background-color: rgba(#73c783, 0.04);
        }
    }

    &#{&}-color-error {
        color: #f086a5;
        &:hover {
            background-color: rgba(#f086a5, 0.04);
        }
    }

    &#{&}-color-warning {
        color: #e98519;
        &:hover {
            background-color: rgba(#e98519, 0.04);
        }
    }

    &#{&}-color-add {
        color: #9fbfdc;
        &:hover {
            background-color: rgba(#9fbfdc, 0.04);
        }
    }

    &#{&}-disabled {
        background-color: transparent;
        color: rgba(0, 0, 0, 0.26);
        cursor: not-allowed;
    }
}




.icon-button-column {
    flex-direction: column;
    #{&} > &-text {
        color: $fontColor-2;
        letter-spacing: 0.1rem;
        font-size: 1.4rem;
        padding: 0 1.1rem;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
    }
    // 修改非正圓的處理
    &.icon-button {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0);
            width: 7.3rem;
            height: 7.3rem;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.04);
        }
        &:hover {
            background-color: transparent;
            &::before {
                transform: translate(-50%, -50%) scale(1);
                transition: transform $easeIn 150ms;
            }
        }
    }
}
