.qr-code-dialog {
  .captcha {
    margin-bottom: 15px;

    .read-text-field-label {
      font-size: $h5FontSize;
    }

    .read-text-field-text {
      font-size: $h5FontSize;
      color: $errorColorMain;
    }
  }
}