.alert-root {
    @include mxVariants();
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    &.alert-justify-content-start {
        justify-content: flex-start;
    }
}

.alert-standard {
    .alert-icon {
        background-color: beige;
    }
    @each $key, $value in $colorMainMap {
        &.alert-color-#{$key} {
            color: lighten($value, 20%);
            background-color: lighten($value, 50%);
            .alert-icon-#{$key} {
                color: $value;
            }
        }
    }
    &.alert-color-gray {
        color: $fontColor-2;
        background-color: $bg-light;
        .alert-icon {
            background-color: $gray-400;
            &-gray {
                color: white;
            }
        }
    }
}

.alert-outlined {
    @each $key, $value in $colorMainMap {
        &.alert-color-#{$key} {
            color: lighten($value, 20%);
            border: 1px solid lighten($value, 10%);
            .alert-icon-#{$key} {
                color: $value;
            }
        }
    }
    &.alert-color-gray {
        color: $fontColor-2;
        background-color: $bg-light;
        .alert-icon {
            background-color: $gray-400;
            &-gray {
                color: white;
            }
        }
    }
}

.alert-filled {
    font-weight: $fontWeightRegular;
    border-radius: $shapeBorderRadius;
    @each $key, $value in $colorMainMap {
        &.alert-color-#{$key} {
            color: $value;
            .alert-icon {
                background-color: $value;
            }
        }
    }
    @each $key, $value in $colorLightMap {
        &.alert-color-#{$key} {
            background-color: $value;
        }
    }
    .svg-icon-root {
        color: #fff;
    }
    &.alert-color-gray {
        color: $fontColor-2;
        background-color: $bg-light;
        .alert-icon {
            background-color: $gray-300;
            &-gray {
                color: white;
            }
        }
    }
}

.alert-icon {
    margin-right: 12px;
    padding: 0.8rem;
    border-radius: 50%;
    display: flex;
    font-size: 2rem;
    opacity: 0.9;
}

.alert-message {
    padding: 8px 0;
}

.alert-action {
    display: flex;
    align-items: flex-start;
    padding: 4px 0 0 16px;
    margin-left: auto;
    margin-right: -8px;
}


