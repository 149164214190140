.leaveTextField {
    &-wrap {
        max-width: calc(50% - 0.8rem);
    }
    &.full-width {
        .leaveTextField-wrap {
            max-width: 100%;
        }
    }
}
