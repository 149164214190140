.more-label-box {
  &-menu {
    .menu-item  {
      min-width: 14rem;
      padding-right: 2rem;
      padding-left: 2rem;
      .list-item-icon {
        margin-right: .6rem;
      }
    }
    &.align-center {
      text-align: center;
    }
  }
}
