/*-------------------- Colors Start --------------------*/
$primaryColorDarken: #6e2f8e !default; //darken(20%);
$primaryColorMain: #a45bc8 !default;
$primaryColorLight: #f0e4f6 !default; //light(36%);

$secondaryColorDarken: #3c2373 !default; //darken(20%);
$secondaryColorMain: #653ac2 !default;
$secondaryColorLight: #e5ddf5 !default; //light(42%);

$warningColorDarken: #893e00 !default; //darken(20%);
$warningColorMain: #ef6c00 !default;
$warningColorLight: #fff3e0 !default;

$errorColorDarken: #c71612 !default; //darken(20%);
$errorColorMain: #ef5350 !default;
$errorColorLight: #ffebee !default;

$successColorDarken: #3a813d !default; //darken(20%);
$successColorMain: #4caf50 !default;
$successColorLight: #f1f8e9 !default;

$infoColorDarken: #0960a5 !default; //darken(20%);
$infoColorMain: #2196f3 !default;
$infoColorLight: #e3f2fd !default; //light(25%);

$markColor: #ffee58;

$primaryGradientMain: linear-gradient(to right, $secondaryColorMain, $primaryColorMain);

/* background */
$backgroundDefault: #f0f0f0 !default;
$backgroundPaper: white !default;

// font color
$fontColor-1: #0c0e19;
$fontColor-2: #424654;
$fontColor-3: #676a92;
$fontColor-4: #aaaebe;
$fontColors: $fontColor-1, $fontColor-2, $fontColor-3, $fontColor-4;

/* Title Color */
$titleColor: $fontColor-1;

/* Text Color */
$textPrimaryColor: $fontColor-1;
$textSecondaryColor: $fontColor-2;
$textDisabledColor: $fontColor-4;

/* divider */
$dividerColor: rgba(0, 0, 0, 0.2);

/* ShowLoading */
$showLoadingBackgroundColor: rgba(0, 0, 0, 0.38);

/* action */
// The colors used to style the action elements.
$actionActiveColor: rgba(0, 0, 0, 0.54); // The color of an active action like an icon button.
$actionHoverColor: rgba(0, 0, 0, 0.06); // The color of an hovered action.
$actionHoverOpacity: 0.04;
$activeSelectedColor: rgba(0, 0, 0, 0.08);
$activeSelectedOpacity: 0.08;
$actionDisabledColor: rgba(0, 0, 0, 0.26); // The color of a disabled action.
$actionDisabledBackground: rgba(0, 0, 0, 0.05); // The background color of a disabled action.
$actionDisabledOpacity: 0.38;
$actionFocusColor: rgba(0, 0, 0, 0.12);
$actionFocusOpacity: 0.12;
$actionActivatedOpacity: 0.12;

$page-bg: #f0f4f8;
$bg-light: #f0f4f6;
$bg-light-active: #e9ecf0;

/*-------------------- Colors End  --------------------*/

/* Font Size and typography */
$htmlFontSize: 10px;
$h1FontSize: 4rem;
$h2FontSize: 3.8rem;
$h3FontSize: 2.8rem;
$h4FontSize: 2.4rem;
$h5FontSize: 2.2rem;
$h6FontSize: 1.8rem;
$buttonFontSize: 1.7rem;
$subtitle1Size: 1.7rem;
$subtitle2Size: 1.6rem;
$body1Size: 1.7rem;
$body2Size: 1.6rem;
$captionSize: 1.2rem;

/* Font weight & Font family */
$fontFamily: 'Noto Sans TC', sans-serif;
$fontSize: $body1Size;

$titleLineHeight: 1.2;
$lineHeight: 1.5;


$fontBackground: #f7f7fb;
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightBold: 700;

$borderColor: rgba(0, 0, 0, 0.24);
$borderColor-2: rgba(0, 0, 0, 0.2);

/*-------------------- Animated Start --------------------*/
// ease
$easeInOut: cubic-bezier(0.4, 0, 0.2, 1);
$easeOut: cubic-bezier(0, 0, 0.2, 1);
$easeIn: cubic-bezier(0.4, 0, 1, 1);
$sharp: cubic-bezier(0.4, 0, 0.6, 1);

// duration
$durationShortest: 150;
$durationShorter: 200;
$durationShort: 250;
$durationStandard: 300;
$durationComplex: 375;
$durationEnteringScreen: 225;
$durationLeavingScreen: 195;
/*-------------------- Animated End --------------------*/

/*-------------------- Zoom Start --------------------*/
// z-index
$mobileStepperZIndex: 1000;
$speedDialZIndex: 1050;
$appBarZIndex: 1100;
$drawerZIndex: 1200;
$modalZIndex: 1300;
$snackbarZIndex: 1400;
$tooltipZIndex: 1500;
$showloadingZIndex: 2000;
/*-------------------- Zoom End --------------------*/

// papers
$paperBorderRadius: 1rem;

// shape
$shapeBorderRadius: 0.4rem;

//Button BorderRadius
$buttonBorderRadius: 0.8rem;

// Grid spacing
$gridSpacing: (.8rem, 1.6rem, 2.4rem, 3.2rem, 4.8rem);

$spacer: 0.8rem;
$spacers: (
    0: 0,
    1: $spacer * 0.5,
    2: $spacer * 2,
    3: $spacer * 2.5,
    4: $spacer * 3,
    5: $spacer * 5,
);

// card
$cardPadding: 2rem;
$cardContentLastBottom: 2.4rem;


// xs: 0, // phone
// sm: 600, // tablets
// md: 900, // small laptop
// lg: 1200, // desktop
// xl: 1536, // large screens  不參考


@import "./variableMap";
