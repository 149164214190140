.simple-content-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-top: 1rem;
  &-label {
    min-width: 6.5rem;
    color: $fontColor-3;
    margin: 0 1.6rem 0 0;
  }
  &-content {
    color: $fontColor-1;
    margin: 0;
    :not(.multiline) & {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &:not(&.multiline) .btn-edit {
    margin-left: auto;
  }
  &.multiline {
    flex-wrap: wrap;
    background-color: $bg-light;
    padding: 1.5rem;
    border-radius: $shapeBorderRadius;
    .simple-content-item {
      &-label {
        color: $primaryColorMain;
        flex-basis: 100%;
        margin-bottom: 1.2rem;
      }
      &-content {
        min-height: 3.2rem;
        white-space: pre-line;
      }
    }
    .btn-edit {
      position: absolute;
      right: .4rem;
      top: .2rem;
    }
  }
}
