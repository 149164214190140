/* NonMember */
.non-member-header {
    display: flex;
    margin: 0 0 24px;
    position: relative;
    justify-content: space-between;
}

.non-member-content {
    margin-top: 24px;
}

/* NonMember -> Customer */
.customer-table-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customer-table-action-icon {
    padding-left: 24px;
}

/* NonMember -> Customer -> add*/
.customer-add-apo-header {
    background: $primaryGradientMain;
    height: 12rem;
    padding: 2.4rem;
}

.customer-add-isapo-switch-panel {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background-color: #e5e5e5;
    padding: 0.8rem 2rem;
    border-radius: 0.2rem;
}

.customer-add-reply-checkbox-panel {
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    background-color: lighten($primaryColorMain, 30%);
    padding: 0.8rem 2rem;
    border-radius: 0.2rem;
}

/* NonMember -> info -> Record  */
.nonmember-info-width-50 {
    width: 50%;
}

.nonmember-info-width-40 {
    width: 40%;
}

.nonmember-info-contact-cotainer {
    background-color: #e2e7f4;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    overflow: auto;
}

$bookmarkWidth: 22;

.nonmember-info-contact-markbook {
    position: relative;
    align-self: center;
    &:before {
        content: '';
        position: absolute;
        background-color: #a45bc8;
        top: 0;
        bottom: 0;
        left: ($bookmarkWidth * -1) + px;
        right: 0;
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
        box-shadow: map-get($shadows, 2);
    }
}

.nonmember-info-contact-date {
    position: relative;
    font-size: 1.6rem;
    margin-left: -10px;
    padding: 2px 10px 2px 0;
    color: #fff;
}

.nonmember-info-contact-label {
    display: inline-block;
    font-weight: $fontWeightLight;
    color: #0e0e0e;
    margin: 0 20px 0 0;
}

.nonmember-info-contact-label-text {
    display: inline-block;
    width: 90px;
}

// components
.customer-brief-name {
    position: relative;
    padding: 0 0 0 18px;
    .state {
        position: absolute;
        background: $primaryColorDarken;
        top: 10px;
        left: 2px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        &.invalid {
            background: gray(500);
        }
    }
}
