.product-details {
  position: relative;
  width: 100%;

  // 通用樣式
  & > * {
    font-size: 14px;
    margin-bottom: 4px;
    line-height: $titleLineHeight;
  }


  .product-details__sku,
  .product-details__spec,
  .product-details__note {
    color: $fontColor-3;
  }

  .product-details__name {
    font-size: 16px;
    font-weight: $fontWeightBold;
    color: $fontColor-1;
  }

  .product-details__spec,
  .product-details__stock {
    color: $infoColorDarken;
  }

  .product-details__price {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
    line-height: 1;
  }

  .product-details__sale-price {
    font-size: 18px;
    font-weight: $fontWeightBold;
    color: $primaryColorMain;

    > .symbol {
      font-size: 14px;
    }
  }

  .product-details__price--original {
    position: relative;
    padding: 10px;
    color: $fontColor-3;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 16px;
      background-color: $borderColor-2;
    }
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}
