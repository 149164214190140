// 初次費用
.paid-list {
    &:not(&:first-child) {
        padding-top: 2rem;
    }
    &:not(&:last-child) {
        padding-bottom: 2rem;
    }
    + #{&} {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            @include mxFakeDashed();
        }
    }
    &-item {
        display: flex;
        justify-content: space-between;
        + #{&} {
            margin-top: 1.6rem;
        }
        &.balance {
            background-color: lighten($errorColorMain, 35%);
            border-radius: $shapeBorderRadius;
        }
        .inputbase-input.inputbase-input-disabled {
            -webkit-text-fill-color: $fontColor-1;
        }
    }
    &-cell {
        display: inline-block;
        line-height: 2.4rem;
        padding: 0.8rem 0.8rem 0.8rem 0;
        white-space: nowrap;
    }
    .align-item-end {
        .stack-root {
            align-items: flex-end;
        }
    }
    .inputbase-input {
        text-align: right;
    }
    .required {
        color: $errorColorMain;
        font-size: $body2Size;
        margin-left: 0.8rem;
        > .text {
            color: $fontColor-3;
            &::before {
                content: '-';
                padding-left: 0.4rem;
                padding-right: 0.4rem;
            }
        }
    }
}

// 費用資訊
.fee-tip {
    font-size: 1.4rem;
    line-height: $lineHeight;
    color: $fontColor-2;
    padding: 1.5rem;
    background-color: #e1f5fe;
    width: 100%;
    border-radius: $shapeBorderRadius;
}
