.giveaway-item {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-bottom: 1.2rem;
    &:not(&:first-child) {
        padding-top: 1.2rem;
    }
    > :first-child {
        flex: 1 1 35%;
        max-width: 35%;
    }
    .form-control-root {
        .inputbase-root {
            min-width: none;
        }
    }

}
