.ban-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cursor-pointer {
    cursor: pointer !important;
}

.dialog-title-header-primary {
    position: relative;
    background: linear-gradient(to right, $primaryColorMain, $secondaryColorMain);
    color: gray(50);
    > .dialog-title-header-close-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0px;
    }
    > .dialog-title-margin {
        margin: 0 2rem;
    }
}

.dialog-dotted-line {
    @include mxFakeDashed();
    margin: 12px 0;
}

.overflow-scroll {
    overflow: scroll !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.visibility-hidden {
    visibility: hidden !important;
}

.sms-points {
    background: darken($primaryColorLight, 10);
    color: $primaryColorDarken;
    border-radius: 6px;
    letter-spacing: 0.3px;
}

.sms-query-date {
    color: $gray-400;
}