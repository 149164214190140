.inbody-interview {
  .header {
    position: relative;
  }

  //.is-measure {
  //    position: absolute;
  //    right: 0;
  //    bottom: 0;
  //}
  .business-card-box {
    overflow: hidden;

    .member-box-name-label {
      font-size: $h5FontSize;
    }
  }
}

.inbody-list {
  padding: 1.6rem 1.4rem;
}

.member-information-page .inbody-measure .notes-content {
  width: unset;
}

.inbody-measure {
  overflow: auto;
  max-height: calc(100vh - 80px);

  .container {
    display: table;
    table-layout: fixed;

    .item-fake-div {
      display: table-cell;
    }

    .row {
      display: table-row;

      &:nth-child(odd) {
        > .item, > .item-fake-div {
          background-color: $bg-light;
        }
      }

      .label {
        white-space: nowrap;
      }

      &.title {
        > .item {
          padding: 20px;
          -webkit-transform: translateZ(0);
          will-change: transform;

          .inputbase-root {
            font-weight: bold;
            border-radius: 30px;
            background-color: rgba(white, .2);
            border: 1px solid;

            .select {
              padding: 8px 35px 9px 14px;
            }

            .svg-icon-root {
              color: darken(white, .2);
            }

            .outlinedinput-notchedOutlin {
              border-width: 0;
            }

            &:hover .outlinedinput-notchedOutlin {
              border-width: 0;
            }
          }
        }

        > .item, > .item-fake-div {
          color: white;
          background-color: $primaryColorMain;
          border-bottom-width: 0;
        }

        > .label {
          position: sticky;
          top: -1px;
          z-index: 400;
        }
      }
    }

    .header {
      position: sticky;
      top: 0;
      z-index: 300;

    }

    .inbody-chart {
      > div {
        border-top: 2px solid $borderColor-2;
      }
    }

    .item {
      display: table-cell;

      &:not(.label) {
        min-width: 260px;
      }

      &.label {
        text-align: right;

        .item-fake-div:last-of-type {
          display: table-cell;
          width: 100%;
        }
      }

      .positive-img {
        width: 30px;
        height: 30px;
        margin-left: 10px;
      }
    }

    .item, .item-fake-div {
      padding: 12px 20px;
      border-bottom: 1px solid $borderColor-2;
      vertical-align: middle;
      background-color: white;
    }
  }
}

.inbody-measure-compare {
  &.inbody-measure {
    .container {
      .title {
        > .item {
          height: 68px;
          padding: 8px 20px;

          .inputbase-root {
            font-weight: bold;
            border-radius: 30px;
            background-color: rgba(white, .2);
            border: 1px solid;

            .select {
              padding: 8px 35px 9px 14px;
            }

            .svg-icon-root {
              color: darken(white, .2);
            }

            .outlinedinput-notchedOutlin {
              border-width: 0;
            }

            &:hover .outlinedinput-notchedOutlin {
              border-width: 0;
            }
          }
        }
      }
    }

    .item {
      .positive-img {
        width: 30px;
        height: 30px;
        margin-left: 10px;
      }
    }
  }
}

.inbody-interview-member-info-card {
  position: relative;

  .inbody-interview-member-content {
    margin-left: 220px;
  }

  .controller-box {
    width: 23rem;
    position: absolute;
    right: 0;
    bottom: 39px;
    z-index: 2;
  }

  .is-measure {
    text-align: right;
  }

  .measure-weight-button {
    width: 100%;
    padding: 1.2rem 2rem;
    border-radius: 30px 0 0 30px;
    font-size: 2rem;
    color: white;
    background-color: $secondaryColorMain;
    box-shadow: map-get($shadows, 4);

    > img {
      width: 22px;
      margin-right: 15px;
    }

    &:hover {
      color: white !important;
      background-color: darken($secondaryColorMain, 5%);
      box-shadow: none;
    }
  }
}

.inbody-interview-member-profile-image {
  position: absolute;
  top: 0;
  left: 0;

  .inbody-interview-member-profile-image-wrap {
    position: relative;
    width: 210px;
    height: 210px;
    overflow: hidden;
    background-color: $gray-300;

    &:hover {
      .profile-image-display {
        &::after {
          opacity: 1;
          transition: opacity .3s;
        }
      }

      .profile-image-uploader .upload-icon {
        opacity: 1;
        transition: opacity .3s;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent url('../../../scripts/icoach/assets/img/profile-picture-cover.png') no-repeat center center;
      background-size: 100% 100%;
      z-index: 3;
      pointer-events: none;
    }

    .profile-image-display {
      img {
        border-radius: 0;
        width: 100%;
        height: auto;
      }

      &::after {
        opacity: 0;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .5);
        transition: opacity .3s;
      }
    }

    .profile-image-uploader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 87%;
      display: flex;
      justify-content: center;
      align-items: center;

      .upload-icon {
        opacity: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: white;
        gap: 4px;
        cursor: pointer;
        transition: opacity .3s;

        .svg-icon-root {
          font-size: 30px;
          color: white;
        }
      }
    }
  }

}

